import { useAtom } from "jotai"
import { meniAtom } from "../../GlobalState/meniAtom"
import { gMeniAtom } from "../../GlobalState/gMeniAtom"
import { useEffect, useState } from "react"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const NavigacijaWidget = ({ naslov = "" }) => {
    const [meni, setMeni] = useAtom(meniAtom)
    const [gmeni, setGMeni] = useAtom(gMeniAtom)
    const [display, setDisplay] = useState(false)
    const [path, setPath] = useState([])

    function findPathInMap(map, targetKey, currentPath = []) {
        for (const [key, value] of map) {
            const newPath = [...currentPath, key];

            if (key === targetKey) {
                return newPath;
            }

            if (typeof value === 'object' && value instanceof Map) {
                const nestedPath = findPathInMap(value, targetKey, newPath);
                if (nestedPath.length > 0) {
                    return nestedPath;
                }
            }
        }

        return map.has(targetKey) ? [...currentPath, targetKey] : [];
    }

    useEffect(() => {
        if (!(Object.keys(meni).length == 0)) {
            let linkoviArr = Array.from(meni['links'].entries()).map((link) => {
                return {
                    link: link[1],
                    ime: meni['names'].get(link[0]),
                    id: link[0]
                }
            }).concat([{ link: window.location.origin, ime: "Почетна", id: 0 }])
            let gornji = linkoviArr.slice(0, 5)
            linkoviArr = linkoviArr.slice(5).concat(gornji)
            //console.log(gornji)
            //console.log(linkoviArr)
            let linkovi = linkoviArr.filter((link) => window.location.href.includes(link.link))
            let link = linkovi.length > 0 ? linkovi.reduce((max, curr) => curr.link.length > max.link.length ? curr : max) : null
            if (link && link.link != (window.location.origin + "/")) setDisplay(true)
            else return
            //console.log(link)
            let linkoviDict = Object.assign({}, ...linkoviArr.map((link) => ({ [link.id]: { link: link.link, ime: link.ime } })))
            let path = link.id == "" ? [0] : [0].concat(findPathInMap(meni['meni_items'], link.id))
            //console.log(findPathInMap(meni['meni_items'], 2321))
            //console.log(path)
            setPath(path.map((x) => {
                return {
                    link: linkoviDict[x].link,
                    ime: linkoviDict[x].ime
                }
            }).concat(path.length == 1 ? [{ link: link.link, ime: link.ime }] : []))
        }
    }, [meni, gmeni])

    return display ? <>
        <div className="navigacija-wrapper">
            <div className="navigacija">
                {path.map((link, index) => {
                    return <div>
                        <a style={{ paddingLeft: `${index * 22}px` }} className="word-wrap" href={link.link}>{link.ime}</a>
                        {index != path.length - 1 ? <FontAwesomeIcon icon={faChevronRight} /> : null}
                    </div>
                })}
            </div>
        </div>
    </> : null
}