import { useState } from "react"
import close from '../../res/images/close.png';
import { FileIcon, defaultStyles } from "react-file-icon"

function ObavestenjeWidget({ obavestenje }) {
    const mesec = (datum) => {
        switch (datum) {
            case '01':
                return 'јануар'
            case '02':
                return 'фебруар'
            case '03':
                return 'март'
            case '04':
                return 'април'
            case '05':
                return 'мај'
            case '06':
                return 'јун'
            case '07':
                return 'јул'
            case '08':
                return 'август'
            case '09':
                return 'септембар'
            case '10':
                return 'октобар'
            case '11':
                return 'новембар'
            default:
                return 'децембар'
        }
    }

    const [popup, setPopup] = useState(false)

    const togglePopup = () => setPopup(!popup)

    if (popup) {
        document.body.classList.add('active-modal')
    }
    else {
        document.body.classList.remove('active-modal')
    }

    return <>
        <li className="obavestenje">
            <h1 onClick={togglePopup}>{obavestenje['naslov']}</h1>
            <h3>{obavestenje['datum'].substring(0, 2) + '. ' + mesec(obavestenje['datum'].substring(3, 5)) + ' ' + obavestenje['datum'].substring(6, 10) + '.'}</h3>
        </li>
        {popup &&
            <>
                <div className="modal">
                    <div className="overlay" onClick={togglePopup}>
                    </div>
                    <div className="content">
                        <div className="close" onClick={togglePopup}>
                            <img src={close}></img>
                        </div>
                        <h1>{obavestenje['naslov']}</h1>
                        <div className="hl"></div>
                        <div className='tekst' dangerouslySetInnerHTML={{ __html: obavestenje['tekst'] }}></div>
                        {obavestenje['fajlovi'].length > 0 &&
                            <>
                                <h1 className="fajlovi-tekst">Приложена документа : </h1>
                                <ul className="fajlovi">
                                    {obavestenje['fajlovi'].map((fajl) => {
                                        return <>
                                            <li className="fajl">
                                                <div className="slikaFajla">
                                                    <FileIcon extension={fajl['ext']} {...defaultStyles[`${fajl['ext']}`]} />
                                                </div>
                                                <a href={fajl['link']}>{fajl['naslov']}</a>
                                            </li>
                                        </>
                                    })}
                                </ul>
                            </>}

                    </div>
                </div>
            </>
        }
    </>
}

export default ObavestenjeWidget