import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slike from "../../komponente/Pocetna/Slike";
import WrapperSidebar from "../../komponente/WrapperSidebar";
import VestiWidget from "../../komponente/Widgets/VestiWidget";
import apiEndpoint from "../../test/config";
import LinkoviWidget from "../../komponente/Widgets/LinkoviWidget";
import Slike2 from "../../komponente/Pocetna/Slike2";
import pozicijeSlika from "../../test/pozicije";
import zameniGalerije from "../../test/zamenaGalerije";
// import VestiStranica from "./VestiStranica";

function VestStranica() {
    let { slug } = useParams()
    // console.log(slug)

    const query = `{
    vestBy(id: "${slug}") {
        acf {
        naslov
        podnaslov
        datumobjave
        fieldGroupName
        zoomdirection
        opcijenaslova
        telovesti
        thumbnail {
          sourceUrl
        }
        shadow
        slikanamobilnom {
            sourceUrl
        }
        drugaslika
      }
      date
    }
    }`

    // console.log(query)
    // console.log(test)

    const dataToVesti = (data) => {
        let opcijeNaslova = data['acf']['opcijenaslova'] == null ? ['levo'] : data['acf']['opcijenaslova']
        let velNaslova = opcijeNaslova.includes('velikaslova') ? 2 : opcijeNaslova.includes('srednjaslova') ? 1 : 0
        let pozNaslova = opcijeNaslova.includes('levo') ? 0 : opcijeNaslova.includes('desno') ? 2 : opcijeNaslova.includes('centar') ? 1 : 0

        let acf = data['acf']
        let vestiArr = {
            zatamljenje: acf['shadow'] == null ? false : true,
            mobUrl: acf['drugaslika'] ? (acf['slikanamobilnom'] == null ? null : acf['slikanamobilnom']['sourceUrl']) : null,
            naslov: acf['naslov'],
            podnaslov: acf['podnaslov'],
            tekst: zameniGalerije(acf['telovesti']),
            pozSlika: pozicijeSlika[acf["zoomdirection"]],
            slika: acf['thumbnail']['sourceUrl'],
            velNaslova: velNaslova,
            pozNaslova: pozNaslova,
            datum: acf['datumobjave'] == null ? data["date"].match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join(".") : acf['datumobjave'].split("/").join(".")
        }
        setVest(vestiArr)
        setVestiB(true)
    }
    const [vest, setVest] = useState({})
    const [vestiB, setVestiB] = useState(false)
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => dataToVesti(data['data']['vestBy']));
    }, []);

    const [widgets, setWidgets] = useState([])
    useEffect(() => {
        fetch(apiEndpoint, {
            method: 'POST', headers: { "Content-Type": 'application/json', "Accept": 'application/json' },
            body: JSON.stringify({
                query: `{pages(where: {name: "SideBar"}) {nodes {sidebar {
                    vesti
                }}}}` })
        }).then((response) => response.json()).then((data) => {
            setWidgets(data['data']['pages']['nodes'][0]['sidebar']['vesti'])
        });
    }, []);
    let slika = [
        {
            zatamljenje: vest['zatamljenje'],
            mobUrl: vest['mobUrl'],
            redniBroj: 0,
            naslov: vest['naslov'],
            opis: vest['podnaslov'],
            velNaslova: vest['velNaslova'],
            pozNaslova: vest['pozNaslova'],
            link_strane: '',
            url: vest['slika'],
        }
    ]

    const mesec = (datum) => {
        switch (datum) {
            case '01':
                return 'Јануар'
            case '02':
                return 'Фебруар'
            case '03':
                return 'Март'
            case '04':
                return 'Април'
            case '05':
                return 'Мај'
            case '06':
                return 'Јун'
            case '07':
                return 'Јул'
            case '08':
                return 'Август'
            case '09':
                return 'Септембар'
            case '10':
                return 'Октобар'
            case '11':
                return 'Новембар'
            default:
                return 'Децембар'
        }
    }

    return <>
        <Slike2 slike={slika}></Slike2>
        <div className="devider100"></div>
        <WrapperSidebar element={<>
            <div className="vest-wrapper">
                {vestiB && <>
                    <h1>{vest['naslov']}</h1>
                    <div className="kategorija-datum">
                        {/* <div className="vl"></div> */}
                        <div className="datum">
                            <h3>{vest['datum'].substring(0, 2) + '. ' + mesec(vest['datum'].substring(3, 5)) + ' ' + vest['datum'].substring(6, 10) + '.'}</h3>
                        </div>
                    </div>
                    <div className="tekst" dangerouslySetInnerHTML={{ __html: vest['tekst'] }}></div>
                </>}
            </div>
        </>} vl_slide={true} widgets={widgets}></WrapperSidebar>
    </>

}

export default VestStranica