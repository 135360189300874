import budi_musko from '../../res/images/budi-musko.png';

function BudiMusko() {
    return <>
        <div className='budi-musko-omotac'>
            <img src='https://wp.gimnazijasvetisava.edu.rs/wp-content/uploads/2024/02/tackice-1.jpg' className='hidden zoom-out blur'></img>
        </div>
    </>
}

export default BudiMusko