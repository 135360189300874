import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiEndpoint from '../test/config';
import DropDownMeni from './Dropdown/DropDownMeni';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAtom } from 'jotai';
import { logoAtom } from '../GlobalState/logoAtom';
import { meniAtom } from '../GlobalState/meniAtom';
import { gMeniAtom } from '../GlobalState/gMeniAtom';
import pozadina from '../../src/res/images/belaPozadina.png';
import Headroom from './EksterneKomponente/HeadRoom';

function GlavniMeni() {
    const [logo, setLogo] = useAtom(logoAtom)
    let ret = new Map();
    const [meni, setMeni] = useAtom(meniAtom)
    const [gMeni, setGMeni] = useAtom(gMeniAtom)
    const [ucitano, setUcitano] = useState(false)
    const [prikazMenija, setPrikazMenija] = useState(false)
    const [windowSize, setWindowSize] = useState({ minWidth: window.innerWidth, minHeight: window.innerHeight })


    function izbrisiCvor(dict, cvor) {
        for (const [key, value] of dict) {
            if (key === cvor) {
                ret = value;
                dict.delete(key);
                return;
            }
            izbrisiCvor(value, cvor);
        }
    }

    function ubaciCvor(dict, parent, child) {
        for (const [key, value] of dict) {
            if (key === parent) {
                value.set(child, ret);
                return;
            }
            ubaciCvor(value, parent, child);
        }
    }

    function napraviDrvo(dict, pairs) {
        for (let i = 0; i < pairs.length; i++) {
            ret = new Map();
            izbrisiCvor(dict, pairs[i][1]);
            ubaciCvor(dict, pairs[i][0], pairs[i][1]);
        }
    }

    function GenerisiMeni(jsonData) {
        let konekcijeMap = new Map();
        let itemNames = new Map();
        let itemLinks = new Map();
        let gornji_meni = []
        try {
            let items = jsonData['menus']['nodes'][0]['menuItems']['edges'].map(x => [x['node']['menuItemId'], x['node']['label'], x['node']['url']]);
            for (let i = 0; i < items.length; i++) itemNames.set(items[i][0], items[i][1]);
            for (let i = 0; i < items.length; i++) {
                let link = items[i][2] == null ? null : items[i][2][0] == '/' ? `${window.location.origin}${items[i][2]}` : items[i][2];
                itemLinks.set(items[i][0], link == null ? '' : link);
            }
            let konekcijeArr = jsonData['menus']['nodes'][0]['menuItems']['nodes'].map(x => x = x['childItems']['nodes']);
            let konekcijePairs = [];
            let konekcijeKeys = [];
            for (let i = 0; i < konekcijeArr.length; i++) {
                for (let j = 0; j < konekcijeArr[i].length; j++) {
                    konekcijePairs.push([konekcijeArr[i][j]['parentDatabaseId'], konekcijeArr[i][j]['databaseId']]);
                }
            }
            for (let i = 0; i < items.length; i++) {
                konekcijeMap.set(items[i][0], new Map());
                konekcijeKeys.push(items[i][0]);
            }
            napraviDrvo(konekcijeMap, konekcijePairs);
            const [firstKey, firstValue] = konekcijeMap.entries().next().value;
            konekcijeMap.delete(firstKey);
            for (const [key, value] of firstValue) {
                gornji_meni.push({
                    ime: itemNames.get(key),
                    link: itemLinks.get(key)
                })
            }
        }
        catch (error) {
            console.log(error)
            return;
        }

        setMeni({
            links: itemLinks,
            names: itemNames,
            meni_items: konekcijeMap
        })
        setGMeni(gornji_meni)
        setUcitano(true)
        // return <DropDownMeni links={itemLinks} names={itemNames} meni_items={konekcijeMap}></DropDownMeni>
    }

    const query = `
    {
        menus(where: {location: HCMS_MENU_HEADER}) {
          nodes {
            menuItems(first: 100) {
              edges {
                node {
                  url
                  label
                  menuItemId
                }
              }
              nodes {
                childItems(first: 100) {
                  nodes {
                    id
                    databaseId
                    parentDatabaseId
                    title
                  }
                }
              }
            }
          }
        }
      }`;
    // const [jsonData, setJsonData] = useState([]);

    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => GenerisiMeni(data['data']));
    }, []);

    const detectSize = () => {
        setWindowSize({ minWidth: window.innerWidth, minHeight: window.innerHeight })
    }

    useEffect(() => {
        window.addEventListener('resize', detectSize)

        return () => {
            window.removeEventListener('resize', detectSize)
        }
    }, [windowSize])

    const navigate = useNavigate()

    return <>
        <Headroom>
            <div className='gmeni-wrapper' style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'white' }}>
                <div className="gornji-meni-wrapper">
                    <img src={logo['logo'] ? logo['logo'] : pozadina} onClick={() => {
                        navigate("/")
                        window.scrollTo(0, 1)
                    }}></img>
                    <div className="mali-meni-wrapper">
                        {ucitano ?
                            gMeni.map((x, i) => {
                                return i == gMeni.length - 1 ? <a href={gMeni[i].link} className="mali-meni-item">{gMeni[i].ime}</a> : <>
                                    <a href={gMeni[i].link} className="mali-meni-item">{gMeni[i].ime}</a>
                                    <div className="vl-mali-meni"></div>
                                </>
                            }) :
                            <>
                                <a className="mali-meni-item"></a>
                                <div className="vl-mali-meni"></div>
                                <a className="mali-meni-item"></a>
                                <div className="vl-mali-meni"></div>
                                <a className="mali-meni-item"></a>
                                <div className="vl-mali-meni"></div>
                                <a className="mali-meni-item"></a>
                            </>
                        }
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#07829D' }}>
                <div className="glavni-meni" id='comp' style={{ display: (windowSize.minWidth > 1100 ? 'flex' : prikazMenija ? 'flex' : 'none') }}>
                    <div className="x">
                        <FontAwesomeIcon icon={faXmark} onClick={() => { setPrikazMenija(false) }} />
                    </div>
                    {ucitano == true ?
                        <DropDownMeni links={meni['links']} names={meni['names']} meni_items={meni['meni_items']}></DropDownMeni>
                        : <DropDownMeni></DropDownMeni>}
                </div>
                <div className="glavni-meni-android">
                    <div>
                        <h1>Ресавска 58, Београд</h1>
                        <div onClick={() => setPrikazMenija(true)}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>

            </div>
        </Headroom>
        <div className="glavni-meni" id='tel' style={{ display: (windowSize.minWidth > 1100 ? 'flex' : prikazMenija ? 'flex' : 'none') }}>
            <div className="x">
                <FontAwesomeIcon icon={faXmark} onClick={() => { setPrikazMenija(false) }} />
            </div>
            {ucitano == true ?
                <DropDownMeni links={meni['links']} names={meni['names']} meni_items={meni['meni_items']}></DropDownMeni>
                : <DropDownMeni></DropDownMeni>}
        </div>
    </>;
}

export default GlavniMeni;