import { useEffect, useRef, useState } from "react"
import apiEndpoint from "../../test/config";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import DobreVibracijeKutija from "./DobreVibracijeKutija";
import { useMediaQuery } from 'react-responsive'

function DobreVibracije() {
    const n = 13;
    let kutijeQuery = (n) => {
        let q = ''
        for (let i = 1; i <= n; i++) {
            q += `kutija${i} {\ntekst\nlink {\nurl\n}\nboja\nslika {\nsourceUrl\n}\n}\n`
        }
        return q
    }

    let query = `{
        pages(where: {name: "Pocetna"}) {
            edges {
                node {
                    pocetna {
                        dobrevibracije {
                            ${kutijeQuery(n)}
                        }
                    }
                }
            }
        }
    }`

    const dataToVesti = (data) => {
        // console.log(data)
        let kutije = []
        for (let i = 1; i <= n; i++) {
            let kutija = data[`kutija${i}`]
            kutije.push({
                tekst: kutija['tekst'],
                link: kutija['link'] == null ? null : kutija['link']['url'],
                slika: kutija['boja'] == null ? kutija['slika']['sourceUrl'] : null,
                boja: kutija['boja'] == null ? null : kutija['boja']
            })
        }
        setKutije(kutije)
        setUcitano(true)
    }
    const [kutije, setKutije] = useState([])
    const [ucitano, setUcitano] = useState(false)
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => dataToVesti(data['data']['pages']['edges'][0]['node']['pocetna']['dobrevibracije']));
    }, []);

    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ['1 0', '0 1']
    })

    const spring = useSpring(scrollYProgress, {
        damping: 50,
        stiffness: 150,
        restDelta: 0.001
    })
    const scale = useTransform(spring, [1, 0.9], [1.3, 1])
    const translateX1 = useTransform(spring, [0.9, 0.75, 0.3, 0], ["0px", "-380px", "-380px", "0px"])
    const translateX1M = useTransform(spring, [0.9, 0.75, 0.3, 0], ["0vw", "-30vw", "-30vw", "0vw"])
    const translateX2 = useTransform(spring, [0.9, 0.75, 0.3, 0], ["0px", "550px", "550px", "0px"])
    const translateX2M = useTransform(spring, [0.9, 0.75, 0.3, 0], ["0vw", "43vw", "43vw", "0vw"])
    const tranlateY = useTransform(spring, [0.81, 0.29], ["0px", "885px"])
    const tranlateYM = useTransform(spring, [0.76, 0.29], ["0vw", "71vw"])

    const isResponsive = useMediaQuery({ query: '(max-width: 1260px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 910px)' })
    
    console.log(isMobile)

    // console.log(scrollYProgress)
    
    return <>
        <div className="dobre-vib-wrapper">
            <div className="naslov hidden left blur">
                <h1>ШИРИ</h1>
                <h2>Добре Вибрације!</h2>
            </div>
            <div className="dobre-vib">
                <div className="content">
                    <div className="isp-linije" ref={ref}></div>
                    <div className="kutije">
                        {ucitano && kutije.map((kutija, index) => {
                            return <DobreVibracijeKutija kutija={kutija} index={index}></DobreVibracijeKutija>
                        })}
                    </div>
                </div>
            </div>
            <div className="kockice">
                <div id='k1' className="hidden blur delay1"></div>
                <div id='k2' className="hidden blur delay1"></div>
                <div id='k3' className="hidden blur delay1"></div>
                <motion.div id='k4' style={{
                    translateX: isMobile ? "none" : isResponsive ? translateX1M : translateX1,
                    translateY: isMobile ? "none" : isResponsive ? tranlateYM : tranlateY,
                    scale: scale,
                    zIndex: 1
                }} className=""></motion.div>
                <motion.div id='k4' style={{
                    translateX: isMobile ? "none" : isResponsive ? translateX2M : translateX2,
                    translateY: isMobile ? "none" : isResponsive ? tranlateYM : tranlateY,
                    scale: scale,
                    zIndex: 1
                }} className=""></motion.div>
                <div id='k5' className="hidden blur delay1"></div>
                <div id='k6' className="hidden blur delay1"></div>
                <div id='k8' className="hidden blur delay1"></div>
                <div id='k7' className="hidden blur delay1"></div>
                <div id='k9' className="hidden blur delay1"></div>
                <div id='k10' className="hidden blur delay1"></div>
                <div id='k11' className="hidden blur delay1"></div>
                <div id='k12' className="hidden blur delay1"></div>
                <div id='k13' className="hidden blur delay1"></div>
                <div id='k14' className="hidden blur delay1"></div>
            </div>
        </div>
    </>
}

export default DobreVibracije