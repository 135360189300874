import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import WrapperSidebar from "../WrapperSidebar"
import VestiWidget from "../Widgets/VestiWidget"
import Slike from "../Pocetna/Slike"
import apiEndpoint from "../../test/config"
import ObavestenjaWidgets from "../Widgets/ObavestenjaWidgets"
import MotoWidget from "../Widgets/MotoWidget"
import LinkoviWidget from "../Widgets/LinkoviWidget"
import { NavigacijaWidget } from "../Widgets/NavigacijaWidget"
import pozicijeSlika from "../../test/pozicije"
import Slike2 from "../Pocetna/Slike2"
import zameniGalerije from "../../test/zamenaGalerije"
import { m } from "framer-motion"

function Generisana({ id }) {
  // console.log(id)

  let query = `{
        stranicaBy(id: "${id}") {
            infostranice {
                telovesti
                telefonbox
                zatelefon
                naslov
                shadow
                drugaslika
                slikanamobilnom{
                  sourceUrl
                }
                linkslug
                zoomdirection
                opcijenaslova
                glavnaslika {
                  sourceUrl
                }
                sidebar {
                    tip
                    krataktekst
                    brzilinkovi {
                      brzilink1 {
                        link {
                          url
                          title
                        }
                        slika {
                          sourceUrl
                        }
                      }
                      brzilink2 {
                        link {
                          url
                          title
                        }
                        slika {
                          sourceUrl
                        }
                      }
                      brzilink3 {
                        link {
                          url
                          title
                        }
                        slika {
                          sourceUrl
                        }
                      }
                      brzilink4 {
                        link {
                          url
                          title
                        }
                        slika {
                          sourceUrl
                        }
                      }
                      brzilink5 {
                        link {
                          url
                          title
                        }
                        slika {
                          sourceUrl
                        }
                      }
                      brzilink6 {
                        link {
                          url
                          title
                        }
                        slika {
                          sourceUrl
                        }
                      }
                    }
                }
            }
        }
    }`

  function updateATags(htmlString) {
    // Create a DOMParser
    const parser = new DOMParser();

    // Parse the HTML string
    const doc = parser.parseFromString(htmlString, 'text/html');

    // Get all the <a> tags in the document
    const aTags = doc.querySelectorAll('a');

    // Loop through each <a> tag and update the target attribute
    aTags.forEach((aTag) => {
      // Check if the target attribute is not '_blank'
      if (!aTag.getAttribute('target') || aTag.getAttribute('target') !== '_blank') {
        // Update the target attribute to '_blank'
        aTag.setAttribute('target', '_blank');
      }
    });

    // Serialize the updated document back to HTML string
    const updatedHtmlString = new XMLSerializer().serializeToString(doc);

    return updatedHtmlString;
  }


  const jsonToData = (data) => {
    //console.log(data)
    let infostranice = data['infostranice']
    let sidebar = infostranice['sidebar']
    let tipovi = sidebar['tip']
    let widgets = []


    if (tipovi != null) {

      if (!tipovi.includes('Ништа')) {
        if (tipovi.includes('Линкови')) {
          let linkovi_arr = []
          for (let i = 1; i <= 6; i++) {
            linkovi_arr.push(sidebar['brzilinkovi'][`brzilink${i}`])
          }
          linkovi_arr = linkovi_arr.map((x) => {
            return {
              link: x['link'] == null ? null : x['link']['url'],
              naslov: x['link'] == null ? null : x['link']['title'],
              slika: x['slika'] == null ? null : x['slika']['sourceUrl']
            }
          })
          widgets.push(<LinkoviWidget linkovi_={linkovi_arr}></LinkoviWidget>)
        }
        if (tipovi.includes('Мото или Текст')) {
          let tekst = sidebar['krataktekst']
          widgets.push(<MotoWidget tekst={tekst}></MotoWidget>)
        }
        if (tipovi.includes('Новости')) {
          widgets.push(<VestiWidget broj_vesti={3}></VestiWidget>)
        }
        if (tipovi.includes('Обавештења')) {
          widgets.push(<ObavestenjaWidgets broj_obavestenja={3}></ObavestenjaWidgets>)
        }
      }
    }

    let opcijeNaslova = infostranice['opcijenaslova'] == null ? ['levo'] : infostranice['opcijenaslova']

    let velNaslova = opcijeNaslova.includes('velikaslova') ? 2 : opcijeNaslova.includes('srednjaslova') ? 1 : 0
    let pozNaslova = opcijeNaslova.includes('levo') ? 0 : opcijeNaslova.includes('desno') ? 2 : opcijeNaslova.includes('centar') ? 1 : 0

    // console.log(opcijeNaslova, velNaslova, pozNaslova)
    // console.log(infostranice['shadow'])

    let stranicaData = {
      zatamljenje: infostranice['shadow'] == null ? false : true,
      mobUrl: infostranice['drugaslika'] ? (infostranice['slikanamobilnom'] == null ? null : infostranice['slikanamobilnom']['sourceUrl']) : null,
      tekst: updateATags(zameniGalerije(infostranice['telovesti'])),
      tekstTelefon: infostranice['zatelefon'] ? updateATags(infostranice['telefonbox']) : null,
      naslov: infostranice['naslov'],
      slug: infostranice['linkslug'],
      slika: infostranice['glavnaslika']['sourceUrl'],
      pozSlika: pozicijeSlika[infostranice['zoomdirection']],
      pozNaslova: pozNaslova,
      velNaslova: velNaslova,
      widgets: widgets.length == 0 ? null : widgets
    }
    setStranica(stranicaData)
    setUcitano(true)
  }
  const [stranica, setStranica] = useState({})
  const [ucitano, setUcitano] = useState(false)
  useEffect(() => {
    fetch(apiEndpoint,
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          query
        })
      }).then((response) => response.json()).then((data) => jsonToData(data['data']['stranicaBy']));
  }, []);

  let { ime } = useParams()
  // console.log(stranica['zatamljenje'])
  return <>
    <Slike2 slike={ucitano && [
      {
        zatamljenje: stranica['zatamljenje'],
        mobUrl: stranica['mobUrl'],
        redniBroj: 0,
        naslov: stranica['naslov'],
        pozSlika: stranica['pozSlika'],
        velNaslova: stranica['velNaslova'],
        pozNaslova: stranica['pozNaslova'],
        opis: "",
        link_strane: '',
        url: stranica['slika'],
      }
    ]}></Slike2>
    <div className="generisana-margin">
      <WrapperSidebar
        element={<>
          <div className='generisana-tekst' id={stranica['tekstTelefon'] == null ? 'active' : 'inactive'} dangerouslySetInnerHTML={{ __html: stranica['tekst'] }}></div>
          {stranica['tekstTelefon'] && <div className='generisana-tekst' id='telefon' dangerouslySetInnerHTML={{ __html: stranica['tekstTelefon'] }}></div>}
        </>}
        vl_slide={true}
        widgets={stranica['widgets']}></WrapperSidebar>
    </div>
  </>
}

export default Generisana