import { faLink } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import apiEndpoint from "../../test/config"

function LinkoviWidget({ linkovi_ = null }) {
  //console.log(linkovi_)
  let query = `{
    pages(where: {name: "SideBar"}) {
      nodes {
        sidebar {
          brzilinkovi {
            brzilink {
              link {
                url
                title
              }
              slika {
                sourceUrl
              }
            }
            brzilink3 {
              link {
                url
                title
              }
              slika {
                sourceUrl
              }
            }
            brzilink2 {
              link {
                url
                title
              }
              slika {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }`

  const dataToVesti = (data) => {
    let linkovi_arr = []
    for (let i = 1; i <= 3; i++) {
      linkovi_arr.push(data[`brzilink${i > 1 ? i : ''}`])
    }
    linkovi_arr = linkovi_arr.map((x) => {
      return {
        link: x['link'] == null ? null : x['link']['url'],
        naslov: x['link'] == null ? null : x['link']['title'],
        slika: x['slika'] == null ? null : x['slika']['sourceUrl']
      }
    })
    setLinkovi(linkovi_arr)
    setUcitano(true)
  }

  const [linkovi, setLinkovi] = useState([])
  const [ucitano, setUcitano] = useState(false)

  useEffect(() => {
    if (linkovi_ == null) {
      fetch(apiEndpoint,
        {
          method: 'POST',
          headers: {
            "Content-Type": 'application/json',
            "Accept": 'application/json'
          },
          body: JSON.stringify({
            query
          })
        }).then((response) => response.json()).then((data) => dataToVesti(data['data']['pages']['nodes'][0]['sidebar']['brzilinkovi']));
    }
    else {
      setLinkovi(linkovi_)
      setUcitano(true)
    }
  }, []);

  //console.log(linkovi)

  return <>
    <div className="linkovi-wrapper">
      <FontAwesomeIcon icon={faLink} />
      <div className="linkovi">
        {ucitano && linkovi.map((link) => {
          return link.slika != null ? <>
            <a className="link" href={link['link']}>
              <img src={link['slika']} className="slika"></img>
            </a>
            <p className="linkovi-naslov">{link['naslov']}</p>
          </> : <></>
        })}
      </div>
    </div>
  </>
}

export default LinkoviWidget