import { motion, useScroll, useSpring, useTransform } from "framer-motion"
import { useRef } from "react"
import { useMediaQuery } from "react-responsive"

function DobreVibracijeKutija({kutija, index}){
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ['1 0.85', '1 1']
    })

    const scaleX = useSpring(scrollYProgress, {
        damping: 30,
        stiffness: 150,
        restDelta: 0.001
    })

    const scale = useTransform(scaleX, [1, 0], [0.8, 1])
    const opacity = useTransform(scaleX, [1, 0], [0.1, 1])
    const isMobile = useMediaQuery({ query: '(max-width: 910px)' })
    // const brightness = useTransform(scaleX, [1, 0], [3, 1])

    // console.log(brightness)

    return <>
    <motion.div 
        ref={ref}
        className="kutija" id={`k${index + 1}`} style={{ 
            background: `${kutija['boja']}`,
            scale: isMobile ? 1 : scale,
            opacity: isMobile ? 1 : opacity,
            zIndex: 5
        }}>
        {kutija['slika'] != null && <img src={kutija['slika']}></img>}
        <p>{kutija['tekst']}</p>
        {kutija['link'] != null && <a href={kutija['link']}></a>}
        <h1>{index + 1}</h1>
    </motion.div>
    <motion.div className="kutija" id={`k${index+1}`} style={{scale: scale, zIndex: 4, background:"#FFFFFF", boxShadow: "none"}}></motion.div>
    </>
}

export default DobreVibracijeKutija