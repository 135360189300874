import React, { useEffect, useRef,} from "react"
import { motion, useInView, useAnimation } from "framer-motion"

interface Props{
    children: JSX.Element
    width?: "fit-content" | "100%"
}

function Reveal ({children, width = "fit-content"} : Props) {
    const ref = useRef(null)
    const isInView = useInView(ref, {once: false})

    const mainControls = useAnimation();

    useEffect(() => {
        if (isInView) {
            mainControls.start("visible")
        }
        else{
            mainControls.start("hidden")
        }
    }, [isInView])

    return (
        <div ref={ref} style={{position: 'relative', width, overflow: 'hidden'}}>
            <motion.div
                viewport={{once: false}}
                variants={{
                    hidden: { opacity: 0, x: 75, position: 'relative'},
                    visible: { opacity: 1, x: 0, position: 'relative'}
                }}
                initial="hidden"
                animate={mainControls}
                transition={{duration: 0.5, delay: 0.1}}
                >
                {children}
            </motion.div>
        </div>
    )
}

export default Reveal