import '../../style.css'
function Greska() {
    const Url = window.location.href;
    return (
        <div className="Wrapper404">
            <h1>Упс!</h1>
            <h3>Страница: {Url} није пронађена</h3>
            <p>Тренутно не можемо да пронађемо страницу коју тражите или страница не постоји.</p>
            <p>Молимо вас да освежите страницу или покушате касније.</p>
            <div className='error-btn-wrap'>
                <a className='error-btn' href='/'>Назад на почетну</a>
            </div>

        </div>
    );
}
export default Greska;