function zameniGalerije(text){
    // pravim novi element u koga ubaujem html kako bi moga njime manipulisati
    var section = document.createElement("section");
    section.innerHTML = text;

    // za svaku galeriju uradi sledece
    let galerije = section.querySelectorAll('.gallery');
    galerije.forEach((galerija) => {

      // uzimam klase kako bi uze broj kolona i velicinu galerije
      let classes = galerija.getAttribute('class')
      let gallerySize = null
      let galleryColumns = null
      if (classes.split(' ').filter((x) => x.includes('gallery-size-')).length != 0)
        gallerySize = classes.split(' ').filter((x) => x.includes('gallery-size-'))[0].split('-')[2]
      if (classes.split(' ').filter((x) => x.includes('gallery-columns-')).length != 0)
        galleryColumns = classes.split(' ').filter((x) => x.includes('gallery-columns-'))[0].split('-')[2]
      
      // menjam stare klase sa novim i uklanjam id
      galerija.setAttribute('class', `post-galerija ${gallerySize ? gallerySize : ''} ${galleryColumns ? 'kol' + galleryColumns : ''}`)
      // if (gallerySize){
      //   if (gallerySize == 'full')
      //     gallerySize = '100%'
      //   else if (gallerySize == 'large')
      //     gallerySize = '350px'
      //   else if (gallerySize == 'medium')
      //     gallerySize = '250px'
      //   else if (gallerySize == 'small')
      //     gallerySize = '150px'
      //   else
      //     gallerySize = '250px'
      // }
      // else 
      //   gallerySize = '250px'
      gallerySize = '290px'
      galerija.setAttribute('style', `columns: ${gallerySize} ${galleryColumns ? galleryColumns : 2};`)
      galerija.removeAttribute('id')

      // za svaku slike uradi sledece
      let slike = galerija.querySelectorAll('dl')
      slike.forEach((slika) => {
        // uzimam link slike i opis
        let img = slika.querySelector('img').getAttribute('src')
        let opis = slika.querySelector('dd') ? slika.querySelector('dd').innerHTML : ''
        // uklanjam stare elemente i pravim nove
        slika.innerHTML = ''
        let slikeEl = document.createElement('dt');
        slikeEl.innerHTML = `<a href="${img}"><img src="${img}" style="width: 100%; height: 100%;"></a>`;
        let opisEl = document.createElement('dd');
        opisEl.innerHTML = opis;
        // postavljam klase i ubacujem elemente
        slika.setAttribute('class', 'slika')
        slika.setAttribute('style', 'border-radius: 5px; width: 100%; aspect-ration: auto; overflow: hidden; transition: transform 0.3s ease-in-out;')
        slika.setAttribute('onMouseOver', 'this.style.transform = "scale(1.02)"')
        slika.setAttribute('onMouseOut', 'this.style.transform = "scale(1)"')
        slika.appendChild(slikeEl);
        slika.appendChild(opisEl);
      })

      //sklanjam br elemente
      let BRs = galerija.querySelectorAll('br')
      BRs.forEach((br) => {
        br.remove()
      })

      console.log(galerija)
    })
    return section.innerHTML
  }

export default zameniGalerije