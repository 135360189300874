import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import WrapperSidebar from "../../komponente/WrapperSidebar"
import VestiWidget from "../../komponente/Widgets/VestiWidget"
import ObavestenjaWidgets from "../../komponente/Widgets/ObavestenjaWidgets"
import Slike from "../../komponente/Pocetna/Slike"
import OstaliPredmeti from "../../komponente/Predmeti/OstaliPredmeti"
import apiEndpoint from "../../test/config"
import SekcijeNastavniciWidget from "../../komponente/Widgets/SekcijeNastavniciWidget"
import Slike2 from "../../komponente/Pocetna/Slike2"
import zameniGalerije from "../../test/zamenaGalerije"
import LinkoviWidget from "../../komponente/Widgets/LinkoviWidget"
import MotoWidget from "../../komponente/Widgets/MotoWidget"

function PredmetStranica() {
  let { id } = useParams()
  console.log(id)
  const query = `{
    predmet(id: "${id}") {
      predmet {
        citat
        imepredmeta
        opispredmeta
        drugaslika
        slikanamobilnom {
          sourceUrl
        }
        shadow
        opcijenaslova
        icon {
          sourceUrl
        }
        velikaslika {
          sourceUrl
        }
        relevantnesekcije {
          ... on Sekcija {
            id
            sekcija {
              ime
            }
          }
        }
        sidebar {
          tip
          krataktekst
        }
        mobverzija
        predmetnamobilnom
      }
    }
  }`

  const query2 = `query NewQuery{
    zaposleni(first: 100) {
      nodes {
        nastavnik {
          predmeti {
            ... on Predmet {
              id
            }
          }
          imeprezime
        }
        id
      }
    }
  }`

  const dataToVesti = (data) => {
    let sidebar = data['sidebar']
    let tipovi = sidebar['tip']
    let widgets = []


    if (tipovi != null) {

      if (!tipovi.includes('Ништа')) {
        if (tipovi.includes('Линкови')) {
          let linkovi_arr = []
          for (let i = 1; i <= 6; i++) {
            linkovi_arr.push(sidebar['brzilinkovi'][`brzilink${i}`])
          }
          linkovi_arr = linkovi_arr.map((x) => {
            return {
              link: x['link'] == null ? null : x['link']['url'],
              naslov: x['link'] == null ? null : x['link']['title'],
              slika: x['slika'] == null ? null : x['slika']['sourceUrl']
            }
          })
          widgets.push(<LinkoviWidget linkovi_={linkovi_arr}></LinkoviWidget>)
        }
        if (tipovi.includes('Мото или Текст')) {
          let tekst = sidebar['krataktekst']
          widgets.push(<MotoWidget tekst={tekst}></MotoWidget>)
        }
        if (tipovi.includes('Новости')) {
          widgets.push(<VestiWidget broj_vesti={3}></VestiWidget>)
        }
        if (tipovi.includes('Обавештења')) {
          widgets.push(<ObavestenjaWidgets broj_obavestenja={3}></ObavestenjaWidgets>)
        }
      }
    }
    console.log(widgets, sidebar, tipovi)
    let opcijeNaslova = data['opcijenaslova'] == null ? ['levo'] : data['opcijenaslova']
    let velNaslova = opcijeNaslova.includes('velikaslova') ? 2 : opcijeNaslova.includes('srednjaslova') ? 1 : 0
    let pozNaslova = opcijeNaslova.includes('levo') ? 0 : opcijeNaslova.includes('desno') ? 2 : opcijeNaslova.includes('centar') ? 1 : 0

    let sekcijaData = {
      ime: data['imepredmeta'],
      widgets: widgets,
      tekstTelefon: data['mobverzija'] && data['predmetnamobilnom'] && zameniGalerije(data['predmetnamobilnom']),
      citat: data['citat'] == null ? null : data['citat'],
      textbox: zameniGalerije(data['opispredmeta']),
      velikaslika: data['velikaslika']['sourceUrl'],
      zatamljenje: data['shadow'] == null ? false : true,
      mobUrl: data['drugaslika'] ? (data['slikanamobilnom'] == null ? null : data['slikanamobilnom']['sourceUrl']) : null,
      malaslika: data['icon']['sourceUrl'],
      sekcije: data['relevantnesekcije'] && data['relevantnesekcije'].map((x) => {
        return {
          id: x['id'],
          ime: x['sekcija']['ime']
        }
      }),
      pozNaslova: pozNaslova,
      velNaslova: velNaslova
    }
    console.log(sekcijaData)
    setSekcija(sekcijaData)
    setUcitanoS(true)
  }

  const dataToNastavnici = (data) => {
    //console.log(data)

    let nastavnici = data.map((nastavnik) => {
      if (nastavnik['nastavnik']['predmeti'] == null) return ''
      let predmeti = nastavnik['nastavnik']['predmeti'].filter(predmet => { return predmet != null })
      return (predmeti.map((predmet) => { return predmet.id }).includes(id) ? { ime: nastavnik['nastavnik']['imeprezime'], id: nastavnik['id'] } : '')
    })
    let test = nastavnici.filter((x) => { return x != '' })
    setNastavnici(test)
    setUcitanoN(true)
  }

  const [sekcija, setSekcija] = useState({})
  const [ucitanoS, setUcitanoS] = useState(false)
  const [nastavnici, setNastavnici] = useState([])
  const [ucitanoN, setUcitanoN] = useState(false)

  useEffect(() => {
    fetch(apiEndpoint,
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          query
        })
      }).then((response) => response.json()).then((data) => dataToVesti(data['data']['predmet']['predmet']));
  }, []);

  useEffect(() => {
    fetch(apiEndpoint,
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          "query": query2
        })
      }).then((response) => response.json()).then((data) => dataToNastavnici(data['data']['zaposleni']['nodes']));
  }, []);


  return <>
    <div className="sekcija-wrapper">
      <Slike2 slike={ucitanoS && [
        {
          zatamljenje: sekcija['zatamljenje'],
          mobUrl: sekcija['mobUrl'],
          redniBroj: 0,
          naslov: sekcija['ime'],
          opis: "",
          link_strane: '',
          velNaslova: sekcija['velNaslova'],
          pozNaslova: sekcija['pozNaslova'],
          url: sekcija['velikaslika'],
        }
      ]}></Slike2>
      <WrapperSidebar element={ucitanoS && <>
        <div className="sekcija">
          {sekcija['citat'] != null && <>
            <h1>{`"${sekcija['citat']}"`}</h1>
            <div className="hl">
              <div></div>
            </div>
          </>}
          <div className="nastavnici">
            <h2>Наставници: </h2>
            <ul>
              {nastavnici.map((nastavnik) => {
                return <Link to={`/kolektiv/${nastavnik.id}`}>{nastavnik.ime}</Link>
              })}
            </ul>
          </div>
          {/* <div className="test" dangerouslySetInnerHTML={{ __html: sekcija['textbox'] }}></div> */}
          <div className='test' id={sekcija['tekstTelefon'] == null ? 'active' : 'inactive'} dangerouslySetInnerHTML={{ __html: sekcija['textbox'] }}></div>
          {sekcija['tekstTelefon'] && <div className='test' id='telefon' dangerouslySetInnerHTML={{ __html: sekcija['tekstTelefon'] }}></div>}
          <OstaliPredmeti></OstaliPredmeti>
        </div>
      </>} vl_slide={true} widgets={sekcija['widgets']}></WrapperSidebar>
    </div>
  </>
}

export default PredmetStranica