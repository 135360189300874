import { useEffect, useState } from "react";
import Slike from "../../komponente/Pocetna/Slike";
import apiEndpoint from "../../test/config";
import { log } from "console";
import Slike2 from "../../komponente/Pocetna/Slike2";
const query = `
{
    pages(where: {name: "Kontakt"}) {
      edges {
        node {
          kontakt {
            mainbox {
              infokutija7 {
                naslov
                kontaktpodaci
              }
              infokutija6 {
                naslov
                kontaktpodaci
              }
              infokutija5 {
                kontaktpodaci
                naslov
              }
              infokutija4 {
                naslov
                kontaktpodaci
              }
              infokutija3 {
                naslov
                kontaktpodaci
              }
              infokutija2 {
                naslov
                kontaktpodaci
              }
              infokutija1 {
                naslov
                kontaktpodaci
              }
            }
            racuni {
              infokutija3 {
                naslov
                kontaktpodaci
              }
              infokutija2 {
                naslov
                kontaktpodaci
              }
              infokutija1 {
                naslov
                kontaktpodaci
              }
            }
          }
        }
      }
    }
  }`;

function KontaktStranica() {

  const [kontakt, setKontakt] = useState([])
  const [racuni, setRacun] = useState([])
  const jsonUPodatke = (jsonData) => {
    let kontaktKutija = [], racuniKutija = [];
    for (let i = 1; i <= 7; i++) {
      let kutija = {
        naslov: jsonData[0]['node']['kontakt']['mainbox'][`infokutija${i}`]['naslov'],
        podaci: jsonData[0]['node']['kontakt']['mainbox'][`infokutija${i}`]['kontaktpodaci']
      }
      kontaktKutija.push(kutija);
    }
    console.log(kontaktKutija)
    for (let i = 1; i <= 3; i++) {
      let kutija = {
        naslov: jsonData[0]['node']['kontakt']['racuni'][`infokutija${i}`]['naslov'],
        podaci: jsonData[0]['node']['kontakt']['racuni'][`infokutija${i}`]['kontaktpodaci']
      }
      racuniKutija.push(kutija)
    }
    setKontakt(kontaktKutija)
    setRacun(racuniKutija)
  }
  useEffect(() => {
    fetch(apiEndpoint,
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          query
        })
      }).then((response) => response.json()).then(function (response) {
        return jsonUPodatke(response['data']['pages']['edges']);
      })
  }, []);
  let slika = [
    {
      redniBroj: 0,
      zatamljenje: true,
      naslov: "Контактирајте нас",
      opis: "Гимназија „Свети Сава\"\n\rРесавска 58\n\r11000",
      link_strane: `${window.location.origin}/o-gimnaziji`,
      url: 'https://wp.gimnazijasvetisava.edu.rs/wp-content/uploads/2023/05/novazgrada-scaled-2-1A.jpg',
    }
  ]

  function highlightEmails(inputString) {
    var pattern = /([\w.-]+@([\w-]+\.)+[\w-]{2,4})/g;
    var phonePattern = /[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*/g;

    var result = inputString.replace(pattern, (match) => `<a class="mail" href="mailto:${match}">${match}</a>`);
    result = result.replace(phonePattern, (match) => `<a class="tel" href="tel:${match}">${match}</a>`);

    return result;
  }

  return <>
    <Slike2 slike={slika}></Slike2>
    <div className="kontakt-wrapper1">
      <div className="kontakti-stranica">
        <div className="levo">
          <h1 className="naslov">Контакт</h1>
          <div className="adresa-ime">
            <h2 className="ime">Гимназија „Свети Сава"</h2>
            <h2 className="adresa">Адреса: Ресавска 58, 11000 Београд</h2>
          </div>
          <ul className="kontakti">
            {kontakt && kontakt.map((kutija) => {
              const podaciNL = kutija['podaci'].split('\r\n')
              return <li className="kontakt">
                <h1 className="zaposlen">{kutija['naslov']}</h1>
                {podaciNL.map((linija) => (
                  <p className="kontaktPodaci" dangerouslySetInnerHTML={{ __html: highlightEmails(linija) }}></p>
                ))
                }
              </li>
            })}

          </ul>
        </div>
        <div className="desno">
          <div className="do-nas">
            <h1 className="naslov">Како до нас?</h1>
            <iframe
              scrolling="no"
              marginHeight={0}
              marginWidth={0}
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Gymnasium%20Saint%20Sava,%20Resavska,%20Belgrade+(%D0%93%D0%B8%D0%BC%D0%BD%D0%B0%D0%B7%D0%B8%D1%98%D0%B0%20%D0%A1%D0%B2%D0%B5%D1%82%D0%B8%20%D0%A1%D0%B0%D0%B2%D0%B0)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              width="100%"
              height="100%"
              frameBorder={0}>
              <a href="https://www.maps.ie/distance-area-calculator.html">area maps</a>
            </iframe>
          </div>
          <div className="racuni-wrapper">
            <h1 className="naslov">Жиро рачуни</h1>
            <ul className="racuni">
              {racuni && racuni.map((kutija) => {
                const podaciNL = kutija['podaci'].split('\r\n')
                //console.log(podaciNL)
                return <li className="racun">
                  <h1 className="ime">{kutija['naslov']}</h1>
                  {podaciNL.map((linija) => (
                    <p className="racunPodaci" dangerouslySetInnerHTML={{ __html: highlightEmails(linija) }}></p>
                  ))
                  }
                </li>
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default KontaktStranica;