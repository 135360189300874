import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import apiEndpoint from "../../test/config"

function Galerije() {
    window.scrollTo(0, 0)

    let query = `{
        fotoGalerije(first: 100000) {
          nodes {
            id
            title
            glavnaSlika {
              sourceUrl
            }
          }
        }
      }`

    const dataToVesti = (data) => {
        //console.log(data)
        let galerijaData = data.map((galerija) => {
            return {
                id: galerija['id'],
                ime: galerija['title'],
                slika: galerija['glavnaSlika']['sourceUrl']
            }
        })
        //console.log(galerijaData)
        setGalerije(galerijaData)
        setUcitano(true)
    }
    const [galerije, setGalerije] = useState([])
    const [ucitano, setUcitano] = useState(false)
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => {
                dataToVesti(data['data']['fotoGalerije']['nodes'])
            });
    }, []);

    return <>
        <div className="galerija-wrapper1">
            <div className="container">
                {ucitano && galerije.map((galerija) => {
                    return <div className="galerija">
                        <div className="slika">
                            <img src={galerija['slika']}></img>
                            <Link to={`/galerije/${galerija.id}`}>{galerija['ime']}</Link>
                        </div>
                        {/* <Link to={`/galerije/${galerija.id}`}>{galerija['ime']}</Link> */}
                    </div>
                })}
            </div>
        </div>
    </>
}

export default Galerije