import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

function DropDownMeniLink({meni_item, meni_items = new Map(), links, names, prvi_red}){
    const meni = meni_items.size > 0
    const w = Math.max(...[...Array.from(meni_items.keys())].map(x => names.get(x).length));
    const [prikaz, setPrikaz] = useState(false)
    const togglePrikaz = () => {setPrikaz(!prikaz)}

    return <>
        <div className="dropdown-meni-link" id={meni ? (prvi_red ? 'prvi' : 'ime-meni') : 'nema-meni'}>
            <div className="dropdown-meni-link-text">
                <a href={links.get(meni_item)} className="word-wrap" style={{pointerEvents: links.get(meni_item) == '' ? 'none' : 'all'}} id={prikaz ? 'sdw' : ''} target={links.get(meni_item).includes(window.location.origin) ? '_self' : '_blank'} >{names.get(meni_item)}</a>
                <FontAwesomeIcon id={prikaz ? 'rot' : ''} icon={faAngleUp} onClick={() => {setPrikaz(!prikaz)}}></FontAwesomeIcon>
            </div>
            {meni && <div className={prikaz ? "dropdown-meni-link-meni prikaz" : "dropdown-meni-link-meni"} style={{ width: 40 + 11 * w + 'px', minWidth: 150 + 'px' }}> 
                <div>
                    {Array.from(meni_items.keys()).map((key) => {
                        return <DropDownMeniLink links={links} names={names} meni_item={key} meni_items={meni_items.get(key)} prvi_red={false}></DropDownMeniLink>
                    })}
                </div>
            </div>}
        </div>
    </>
}

export default DropDownMeniLink