import { useEffect, useState } from "react";
import VaznaObavestenja from "../../komponente/Obavestenja/VaznaObavestenja"
import Slike from "../../komponente/Pocetna/Slike"
import apiEndpoint from "../../test/config";
import pozicijeSlika from "../../test/pozicije";
import WrapperSidebar from "../../komponente/WrapperSidebar";
import Slike2 from "../../komponente/Pocetna/Slike2";


function ObavestenjaStranica() {
    let query = `{
        post(id: "cG9zdDoyNTM2") {
          glavneSlike {
            obavestenjastranica {
              deskripcija
              naslov
              shadow
              drugaslika
              slikanamobilnom {
                sourceUrl
              }
              zoomdirection
              opcijenaslova
              glavnaslika {
                sourceUrl
              }
            }
          }
        }
      }`
    const [izgled, setIzgled] = useState([]);
    const jsonUSlike = (jsonData) => {
        let opcijeNaslova = jsonData['post']['glavneSlike']['obavestenjastranica']['opcijenaslova'] == null ? ['levo'] : jsonData['post']['glavneSlike']['obavestenjastranica']['opcijenaslova']

        let velNaslova = opcijeNaslova.includes('velikaslova') ? 2 : opcijeNaslova.includes('srednjaslova') ? 1 : 0
        let pozNaslova = opcijeNaslova.includes('levo') ? 0 : opcijeNaslova.includes('desno') ? 2 : opcijeNaslova.includes('centar') ? 1 : 0

        let slika = [
            {
                zatamljenje: jsonData["post"]["glavneSlike"]["obavestenjastranica"]['shadow'] == null ? false : true,
                mobUrl: jsonData["post"]["glavneSlike"]["obavestenjastranica"]['drugaslika'] ? (jsonData["post"]["glavneSlike"]["galerijastranica"]['slikanamobilnom'] == null ? null : jsonData["post"]["glavneSlike"]["galerijastranica"]['slikanamobilnom']['sourceUrl']) : null,
                redniBroj: 0,
                naslov: jsonData["post"]["glavneSlike"]["obavestenjastranica"]["naslov"],
                pozSlika: pozicijeSlika[jsonData["post"]["glavneSlike"]["obavestenjastranica"]["zoomdirection"]],
                opis: jsonData["post"]["glavneSlike"]["obavestenjastranica"]["deskripcija"] == null ? ' ' : jsonData["post"]["glavneSlike"]["obavestenjastranica"]["deskripcija"],
                link_strane: '',
                pozNaslova: pozNaslova,
                velNaslova: velNaslova,
                url: jsonData["post"]["glavneSlike"]["obavestenjastranica"]["glavnaslika"]["sourceUrl"],
            }
        ]
        setIzgled(slika);
    }
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => jsonUSlike(data['data']))
    }, []);
    return <>
        <div>
            <Slike2 slike={izgled}></Slike2>
            <WrapperSidebar element={<VaznaObavestenja></VaznaObavestenja>}></WrapperSidebar>
        </div>
    </>
}

export default ObavestenjaStranica