import DropDownMeniLink from "./DropDownMeniLink"

function DropDownMeni({meni_items = new Map(), links = new Map(), names = new Map()}){
    return <>
        <div className="dropdown-meni">
            {Array.from(meni_items.keys()).map((x) => {
                return <DropDownMeniLink prvi_red={true} links={links} names={names} meni_item={x} meni_items={meni_items.get(x)}></DropDownMeniLink>
            })}
        </div>
    </>
}

export default DropDownMeni