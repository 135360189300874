import { Route, Routes } from 'react-router-dom';
import PocetnaStranica from './stranice/Pocetna/PocetnaStranica'
import './style.css'
import './css/mediaQueries/max1530.css'
import './css/mediaQueries/max1260.css'
import './css/mediaQueries/max1100.css'
import './css/mediaQueries/max910.css'
import './css/mediaQueries/max575.css'
import './css/mediaQueries/min911.css'
import './css/mediaQueries/min1170.css'
import './css/wp.css'
import './css/mce.css'
import GlavniMeni from './komponente/GlavniMeni';
import Footer from './komponente/Footer';
import ObavestenjaStranica from './stranice/Obavestenja/ObavestenjaStranica';
import Greska from './stranice/NotFound/404_stranica';
import VestiStranica from './stranice/Vesti/VestiStranica';
import VestStranica from './stranice/Vesti/VestStranica';
import Generisana from './stranice/Generisana/GenerisanaStranica';
import GalerijeStranica from './stranice/Galerije/GalerijeStranica';
import KolektivStranica from './stranice/Kolektiv/KolektivStranica';
import ZaposljenStranica from './stranice/Kolektiv/ZaposljenStranica';
import SekcijeStranica from './stranice/Sekcije/SekcijeStranica';
import SekcijaStranica from './stranice/Sekcije/SekcijaStranica';
import GalerijaStranica from './stranice/Galerije/GalerijaStranica';
import PredmetiListaStranica from './stranice/Predmeti/PredmetiListaStranica';
import PredmetStranica from './stranice/Predmeti/PredmetStranica';
import DokumentaStranica from './stranice/JavnaDokumenta/DokumentaStranica';
import KontaktStranica from './stranice/Kontkt/KontaktStranica';
import Headroom from './komponente/EksterneKomponente/HeadRoom/index.js'
import KompoenenteTest from './komponente/KomponenteTest';
import apiEndpoint from "../src/test/config";
import { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { logoAtom } from './GlobalState/logoAtom';
function App() {

  let query = `{
    post(id: "cG9zdDoyODEz") {
      logoSkole {
        logo {
          sourceUrl
        }
        skrozbelilogo {
          sourceUrl
        }
      }
    }
  }`

  const [logo, setLogo] = useAtom(logoAtom)
  function jsonToLogo(jsonData){
    setLogo({
      logo: jsonData['logo']['sourceUrl'],
      beli_logo: jsonData['skrozbelilogo']['sourceUrl']
    })
  }

  useEffect(() => {
    fetch(apiEndpoint,
      {
          method: 'POST',
          headers: {"Content-Type": 'application/json', "Accept": 'application/json'},
          body: JSON.stringify({ query })
      }).then((response) => response.json()).then((data) => jsonToLogo(data['data']['post']['logoSkole']));
  }, [])

  return <>
    {/* <Headroom > */}
    <GlavniMeni></GlavniMeni>
    {/* </Headroom> */}
    {/* <GlavniMeni></GlavniMeni> */}
    <Routes>
      <Route path='/' element={<PocetnaStranica></PocetnaStranica>}></Route>
      <Route path='/:ime' element={<Generisana></Generisana>}></Route>
      <Route path='/:ime/:ime' element={<Generisana></Generisana>}></Route>
      <Route path='/:ime/:ime/:ime' element={<Generisana></Generisana>}></Route>
      <Route path='/:ime/:ime/:ime/:ime' element={<Generisana></Generisana>}></Route>
      <Route path='/:ime/:ime/:ime/:ime/:ime' element={<Generisana></Generisana>}></Route>
      <Route path='/obavestenja' element={<ObavestenjaStranica></ObavestenjaStranica>}></Route>
      <Route path="*" element={<Greska></Greska>}></Route>
      <Route path='/vesti' element={<VestiStranica></VestiStranica>}></Route>
      <Route path='/vesti/:slug' element={<VestStranica></VestStranica>}></Route>
      <Route path='/galerije' element={<GalerijeStranica></GalerijeStranica>}></Route>
      <Route path='/galerije/:id' element={<GalerijaStranica></GalerijaStranica>}></Route>
      <Route path='/kolektiv' element={<KolektivStranica></KolektivStranica>}></Route>
      <Route path='/kolektiv/:id' element={<ZaposljenStranica></ZaposljenStranica>}></Route>
      <Route path='/sekcije' element={<SekcijeStranica></SekcijeStranica>}></Route>
      <Route path='/sekcija/:id' element={<SekcijaStranica></SekcijaStranica>}></Route>
      <Route path='/predmeti' element={<PredmetiListaStranica></PredmetiListaStranica>}></Route>
      <Route path='/predmet/:id' element={<PredmetStranica></PredmetStranica>}></Route>
      <Route path='/javna-dokumenta' element={<DokumentaStranica></DokumentaStranica>}></Route>
      <Route path='/kontakt' element={<KontaktStranica></KontaktStranica>}></Route>
      <Route path='/testiranje' element={<KompoenenteTest></KompoenenteTest>}></Route>
    </Routes>
    <Footer></Footer>
  </>
}

export default App;