import { useEffect, useState } from "react";
import apiEndpoint from "../../test/config"
import Kolektiv from "../../komponente/Kolektiv/Kolektiv";
import Slike from "../../komponente/Pocetna/Slike";
import Loading from "../Loading stranica/Loading";
import pozicijeSlika from "../../test/pozicije";
import Slike2 from "../../komponente/Pocetna/Slike2";

const predmeti = new Set()
const predmetiIdIme = {}
const predmetiIdSlug = {}

function KolektivStranica() {
  let kategorija = new URL(window.location.href).searchParams.get("kat")
  //console.log(kategorija)

  var query = `{
        post(id: "cG9zdDoyNTM2") {
          glavneSlike {
            zaposlenistranica {
              naslov
              shadow
              drugaslika
              slikanamobilnom {
                sourceUrl
              }
              zoomdirection
              opcijenaslova
              deskripcija
              glavnaslika {
                sourceUrl
              }
            }
          }
        }
        taxonomy(id: "dGF4b25vbXk6dnJzdGF6YXBvc2xlbm9n") {
          name
          connectedTerms(first: 10000) {
            nodes {
              ... on VrstaZaposlenog {
                id
                name
                zaposleni(first: 10000) {
                  nodes {
                    id
                    nastavnik {
                      imeprezime
                      slika {
                        sourceUrl
                      }
                      predmeti {
                        ... on Predmet {
                          id
                          predmet {
                            imepredmeta
                            strucnovece {
                              id
                              slug
                              name
                            }
                          }
                        }
                      }
                      deskripcija
                    }
                  }
                }
              }
            }
          }
        }
      }`

  const [zaposleni, setZaposleni] = useState({})
  const [izgled, setIzgled] = useState([])
  const [ucitano, setUcitano] = useState(false)

  function dataToZaposljeni(jsonData, izgledData) {
    let opcijeNaslova = izgledData['opcijenaslova'] == null ? ['levo'] : izgledData['opcijenaslova']
    let velNaslova = opcijeNaslova.includes('velikaslova') ? 2 : opcijeNaslova.includes('srednjaslova') ? 1 : 0
    let pozNaslova = opcijeNaslova.includes('levo') ? 0 : opcijeNaslova.includes('desno') ? 2 : opcijeNaslova.includes('centar') ? 1 : 0

    let slika = [
      {
        zatamljenje: izgledData['shadow'] == null ? false : true,
        mobUrl: izgledData['drugaslika'] ? (izgledData['slikanamobilnom'] == null ? null : izgledData['slikanamobilnom']['sourceUrl']) : null,
        redniBroj: 0,
        naslov: izgledData["naslov"],
        pozSlika: pozicijeSlika[izgledData["zoomdirection"]],
        opis: izgledData["deskripcija"] == null ? ' ' : izgledData["deskripcija"],
        link_strane: '',
        pozNaslova: pozNaslova,
        velNaslova: velNaslova,
        url: izgledData["glavnaslika"]["sourceUrl"],
      }
    ]
    setIzgled(slika);

    jsonData = jsonData.filter((tip) => {
      for (var i in tip) return true
      return false
    })

    function isNumeric(str) {
			return !isNaN(str) && !isNaN(parseFloat(str))
		}

    let tipoviDict = Object.assign({}, ...jsonData.map((tip) => ({
      [tip.id]: {
        ime: tip['name'],
        zaposleni: tip['zaposleni']['nodes'].map((zaposljen) => {
          let nastavnik = zaposljen['nastavnik']
          let predmetiArr = []
          if (nastavnik['predmeti'] != null) for (let i = 0; i < nastavnik['predmeti'].length; i++) {
            if (nastavnik['predmeti'][i] == null) continue
            let id = nastavnik['predmeti'][i]['predmet']['strucnovece']['id'];
            let ime = nastavnik['predmeti'][i]['predmet']['strucnovece']['name'];
            let slug = isNumeric(nastavnik['predmeti'][i]['predmet']['strucnovece']['slug']) ? parseInt(nastavnik['predmeti'][i]['predmet']['strucnovece']['slug']) : 1000;
            if (!Object.keys(predmetiIdIme).includes(id)) {
              predmetiIdIme[id] = ime
            }
            if (!Object.keys(predmetiIdSlug).includes(id)) {
              // predmetiIdIme[id] = slug
              predmetiIdSlug[id] = slug
            }
            predmeti.add(id)
            predmetiArr.push(id)
          }
          return {
            predmeti: predmetiArr,
            ime: nastavnik['imeprezime'],
            opis: nastavnik['deskripcija'],
            slika: nastavnik['slika'] == null ? null : nastavnik['slika']['sourceUrl'],
            id: zaposljen['id']
          }
        })
      }
    })))

    console.log(tipoviDict)

    setZaposleni(tipoviDict)
    setUcitano(true)
  }

  useEffect(() => {
    fetch(apiEndpoint,//apiEndpoint
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          query
        })
      }).then((response) => response.json()).then((data) => dataToZaposljeni(data['data']["taxonomy"]["connectedTerms"]["nodes"], data['data']['post']['glavneSlike']['zaposlenistranica']));
  }, []);

  return <>
    {ucitano ? <>
      <Slike2 slike={izgled}></Slike2>
      <div style={{ height: "100px" }}></div>
      <Kolektiv zaposleni={zaposleni} predmeti={Array.from(predmeti)} predmetiImena={predmetiIdIme} predmetiSlugs={predmetiIdSlug}
        tip={kategorija ? Object.keys(zaposleni).map((key) => { return zaposleni[key].ime }).includes(kategorija) ? Object.keys(zaposleni).find((key) => zaposleni[key].ime == kategorija) : "" : ""}></Kolektiv>
    </> : <Loading></Loading>}
  </>
}

export default KolektivStranica;