import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import { FileIcon, defaultStyles } from "react-file-icon";
import apiEndpoint from "../../test/config";

function Dokumenta() {
    const params = new URLSearchParams(window.location.search);
    let kategorija = ''

    let test = Array.from(params)
    for (let i = 0; i < test.length; i++) {
        if (test[i][0] == 'kategorija') {
            kategorija = test[i][1]
        }
    }

    let query = `
    query NewQuery {
        dokumentaGimnazije(first: 100000) {
            nodes {
                date
                id
                dokumenta {
                    naslov
                    tipDokumenta {
                        name
                        id
                    }
                    dokument {
                        fileSize
                        title
                        mediaItemUrl
                    }
                    dokumentpost {
                        ... on Dokument {
                            id
                        }
                    }
                    displaydocumentpage
                }
            }
        }
    }`

    const dataToVesti = (data) => {
        let dokumentaDict = {}
        let ostalo = []
        let IdDict = []
        for (let i = 0; i < data.length; i++) {
            let dokument = data[i];
            let doc = {
                id: dokument['id'],
                parentId: dokument['dokumenta']['dokumentpost'] == null ? null : dokument['dokumenta']['dokumentpost']['id'],
                datum: dokument['date'],
                prednjaStana: dokument['dokumenta']['displaydocumentpage'] == null ? false : true,
                naslov: dokument['dokumenta']['naslov'],
                tipovi: dokument['dokumenta']['tipDokumenta'] == null ?
                    null : dokument['dokumenta']['tipDokumenta'].map((tip) => {
                        return tip['name']
                    }),
                fajlovi: [{
                    velicina: dokument['dokumenta']['dokument']['fileSize'],
                    naslov: dokument['dokumenta']['dokument']['title'],
                    link: dokument['dokumenta']['dokument']['mediaItemUrl'],
                }]
            }
            if (doc.tipovi == null) {
                ostalo.push(doc);
            }
            else IdDict[doc['id']] = doc;
        }

        //console.log(ostalo)
        for (let i = 0; i < ostalo.length; i++) {
            IdDict[`${ostalo[i]['parentId']}`]['fajlovi'].push(ostalo[i]['fajlovi'][0]);
        }

        Object.keys(IdDict).forEach((id) => {
            for (let j = 0; j < IdDict[id].tipovi.length; j++) {
                if (dokumentaDict[`${IdDict[id].tipovi[j]}`] == null) {
                    dokumentaDict[`${IdDict[id].tipovi[j]}`] = [IdDict[id]];
                }
                else dokumentaDict[`${IdDict[id].tipovi[j]}`].push(IdDict[id]);
            }
        })
        setDokumenta(dokumentaDict)
        setUcitano(true)
    }
    const [dokumenta, setDokumenta] = useState({})
    const [ucitano, setUcitano] = useState(false)
    //console.log()
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => {
                dataToVesti(data['data']['dokumentaGimnazije']['nodes'])
            });
    }, []);

    let kategorije = () => {
        return Object.keys(dokumenta).map((key) => { return key })
    }

    let kategorijaB = () => {
        return kategorije().includes(kategorija)
    }

    return <>
        {ucitano && <div className="dokumenta-wrapper">
            {Object.keys(dokumenta).map((tip) => {
                if (tip == 'Остало') return <></>
                if (kategorijaB() && tip != kategorija) return <></>;
                return <div className="folder">
                    {kategorijaB() ? <a
                        className="naslov">{tip}</a>
                        : <a
                            href={`${window.location.origin}/javna-dokumenta?kategorija=${tip}`}
                            className="naslov" id='link'>{tip}</a>}
                    {dokumenta[`${tip}`].map((dokument) => {
                        if (!kategorijaB() && !dokument['prednjaStana']) return <></>
                        return <div className="kutija">
                            <h2 className="podnaslov">{dokument['naslov']}</h2>
                            <ul className="dokumenta">
                                {dokument['fajlovi'].map((file) => {
                                    return <li className="dokument">
                                        <FontAwesomeIcon icon={faArrowRight} className="strelica" />
                                        <a href={file['link']} target="_blank" className="link word-wrap">{file['naslov']}</a>
                                        <FileIcon
                                            extension={file['link'].match(/\.([0-9a-z]+)(?:[\?#]|$)/i).pop()}
                                            {...defaultStyles[`${file['link'].match(/\.([0-9a-z]+)(?:[\?#]|$)/i).pop()}`]} />
                                    </li>
                                })}
                            </ul>
                        </div>
                    })}
                </div>
            })}
        </div>}
    </>
}

export default Dokumenta