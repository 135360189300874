import GlavniMeni from "../../komponente/GlavniMeni";
import ucenici from '../../res/images/ucenici.svg';
import nastavnici from '../../res/images/nastavnici.svg';
import roditelji from '../../res/images/roditelji.svg';
import Upis from "../../komponente/Pocetna/Upis";
import Smerovi from "../../komponente/Pocetna/Smerovi";
import OglasnaTabla from "../../komponente/Pocetna/OglasnaTabla";
import Slike from "../../komponente/Pocetna/Slike";
import { Link } from "react-router-dom"
import { useEffect, useLayoutEffect, useState } from "react";
import Cvijic from "../../komponente/Pocetna/Cvijic";
import BudiMusko from "../../komponente/Pocetna/BudiMusko";
import Vesti from "../../komponente/Pocetna/Vesti";
import SvetiSava from "../../komponente/Pocetna/SvetiSava";
import DobreVibracije from "../../komponente/Pocetna/DobreVibracije";
import apiEndpoint from "../../test/config";
import TileFlip from "../../komponente/EksterneKomponente/TileFlip/TileFlip";
import Galerija2 from "../../komponente/Pocetna/Galerija2";
import pozicijeSlika from "../../test/pozicije";
import Slike2 from "../../komponente/Pocetna/Slike2";
import NajavaDesavanje from "../../komponente/Pocetna/NajavaDesavanje";
import observer from "../../animacije/Observer";
import { useMediaQuery } from "react-responsive";

function PocetnaStranica() {
  const str = `
  `
  const query = `
  {
    slideshowGalerija(first: 10) {
      edges {
        node {
          ...SlideshowFields
        }
      }
    }
    pages(where: {name: "Pocetna"}) {
      nodes {
        pocetna {
          infokutija {
            naslov
            saznajvise {
              url
              title
              target
            }
            tekstkutija
            slika {
              sourceUrl
            }
            slikapozadine {
              sourceUrl
            }
          }
          trikutije {
            desnakutija {
              backtext
              bojanaslova
              ikonica {
                sourceUrl
              }
              link{
                url
              }
              naslov
              bojapozadine
              bojaivice
            }
            levakutija {
              backtext
              bojanaslova
              ikonica {
                sourceUrl
              }
              link{
                url
              }
              naslov
              bojapozadine
              bojaivice
            }
            srednjakutija {
              backtext
              bojanaslova
              ikonica {
                sourceUrl
              }
              link{
                url
              }
              naslov
              bojapozadine
              bojaivice
            }
          }
        }
      }
    }
  }
  
  fragment SlideshowFields on Slideshow {
    naslov
    opcijeNaslova
    redniBroj
    slika {
      sourceUrl
    }
    centarSlike
    opis
    linkStrane
    shadow
    drugaslika
    slikanamobilnom {
      sourceUrl
    }
  }`;
  const [slike, postaviSlike] = useState([]);
  const [triPolja, postaviTriPolja] = useState([]);
  const [pozainda, postaviPozadinu] = useState('https://img.freepik.com/free-photo/abstract-surface-textures-white-concrete-stone-wall_74190-8189.jpg?size=626&ext=jpg&ga=GA1.1.632798143.1705514400&semt=ais');
  const [ucitano, setUcitano] = useState(false);

  function compare(a, b) {
    if (a.redniBroj < b.redniBroj) {
      return -1;
    }
    if (a.redniBroj > b.redniBroj) {
      return 1;
    }
    return 0;
  }

  let pozicijeNaslova = []
  pozicijeNaslova['pos1'] = 0
  pozicijeNaslova['pos2'] = 1
  pozicijeNaslova['pos3'] = 2

  const jsonUSlike = (jsonData) => {

    let slikeArr = []
    jsonData['slideshowGalerija']['edges'].forEach((element, index) => {
      let opcijeNaslova = jsonData['slideshowGalerija']['edges'][index]['node']['opcijeNaslova'] == null ? ['levo'] : jsonData['slideshowGalerija']['edges'][index]['node']['opcijeNaslova']
      let velNaslova = opcijeNaslova.includes('velikaslova') ? 2 : opcijeNaslova.includes('srednjaslova') ? 1 : 0
      let pozNaslova = opcijeNaslova.includes('levo') ? 0 : opcijeNaslova.includes('desno') ? 2 : opcijeNaslova.includes('centar') ? 1 : 0

      slikeArr.push({
        zatamljenje: element['node']['shadow'],
        mobUrl: element['node']['drugaslika'] ? (element['node']['slikanamobilnom'] == null ? null : element['node']['slikanamobilnom']['sourceUrl']) : null,
        redniBroj: element['node']['redniBroj'],
        naslov: element['node']['naslov'],
        pozNaslova: pozNaslova,
        velNaslova: velNaslova,
        pozSlika: pozicijeSlika[element['node']['centarSlike'][0]],
        opis: element['node']['opis'],
        link_strane: element['node']['linkStrane'],
        url: element['node']['slika']['sourceUrl']
      })
    });

    let imena = ['levakutija', 'srednjakutija', 'desnakutija']
    let tripoljaArr = imena.map((ime) => {
      let kutijaJson = jsonData['pages']['nodes'][0]['pocetna']['trikutije'][`${ime}`]
      return {
        poruka: kutijaJson['backtext'],
        bojaNaslova: kutijaJson['bojanaslova'],
        bojaPozadine: kutijaJson['bojapozadine'],
        bojaIvice: kutijaJson['bojaivice'],
        naslov: kutijaJson['naslov'],
        ikonica: kutijaJson['ikonica']['sourceUrl'],
        link: kutijaJson['link']['url']
      }
    })
    let poz = jsonData.pages.nodes[0].pocetna.infokutija.slikapozadine['sourceUrl']
    if (poz != null) {
      postaviPozadinu(poz)
    }
    postaviTriPolja(tripoljaArr)
    slikeArr.sort(compare)
    postaviSlike(slikeArr)
    setUcitano(true)
  }
  const ErrorSlika = () => {
    let ArrSlike = []
    ArrSlike.push({
      redniBroj: 0,
      naslov: "Грешка: Нема Учитаних слајдова",
      opis: "",
      link_strane: "",
      url: "https://gimnazijasvetisava.edu.rs/wp-content/uploads/2023/07/clipboard_image_206c485a22a999.png"
    })
    let ArrTriPolja = [{
      poruka: '',
      boja: '#9BDD58',
      naslov: 'Ученици',
      ikonica: ucenici,
    },
    {
      poruka: '',
      boja: '#F0B92C',
      naslov: 'Наставници',
      ikonica: nastavnici,
    },
    {
      poruka: '',
      boja: '#E97772',
      naslov: 'Родитељи',
      ikonica: roditelji,
    }]
    postaviTriPolja(ArrTriPolja)
    postaviSlike(ArrSlike)
  }

  useEffect(() => {
    fetch(apiEndpoint,
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          query
        })
      }).then((response) => response.json()).then((data) => jsonUSlike(data['data'])).catch(
        ErrorSlika
      )
  }, []);
  const findHidden = () => {
    // console.log('called')
    document.querySelectorAll('.hidden').forEach((el) => { observer.observe(el) })
    setTimeout(findHidden, 250)
  }
  useLayoutEffect(() => {
    findHidden()
  }, [])


  const isMobile = useMediaQuery({ query: '(max-width: 910px)' })

  return <form style={{ height: 'auto' }}>
    {/* <Slike slike={slike}></Slike> */}
    <Slike2 slike={slike}></Slike2>
    <div className="w65p-wrapper" style={{ backgroundImage: `url("${pozainda}")` }}>
      <div className="w65p">
        {/* <div className="dash"></div> */}
        <div className="tri-polja-wrapper">
          {ucitano && <><TileFlip ikonica={triPolja[0]['ikonica']} className={isMobile ? 'none' : 'hidden left blur'}
            naslov={triPolja[0]['naslov']}
            poruka={triPolja[0]['poruka']}
            boja={triPolja[0]['bojaPozadine']}
            link={triPolja[0]['link']}
            bojaIvice={triPolja[0]['bojaIvice']}
            bojaNaslova={triPolja[0]['bojaNaslova']}></TileFlip>
            <TileFlip ikonica={triPolja[1]['ikonica']} className={isMobile ? 'none' : 'hidden down blur'}
              naslov={triPolja[1]['naslov']}
              poruka={triPolja[1]['poruka']}
              boja={triPolja[1]['bojaPozadine']}
              link={triPolja[1]['link']}
              bojaIvice={triPolja[1]['bojaIvice']}
              bojaNaslova={triPolja[1]['bojaNaslova']}></TileFlip>
            <TileFlip ikonica={triPolja[2]['ikonica']} className={isMobile ? 'none' : 'hidden right blur'}
              naslov={triPolja[2]['naslov']}
              poruka={triPolja[2]['poruka']}
              boja={triPolja[2]['bojaPozadine']}
              link={triPolja[2]['link']}
              bojaIvice={triPolja[2]['bojaIvice']}
              bojaNaslova={triPolja[2]['bojaNaslova']}></TileFlip></>}
        </div>
        <Upis></Upis>
      </div>
    </div>
    <Smerovi></Smerovi>
    <OglasnaTabla></OglasnaTabla>
    <NajavaDesavanje></NajavaDesavanje>
    <Cvijic></Cvijic>
    <Vesti></Vesti>
    <BudiMusko></BudiMusko>
    <DobreVibracije></DobreVibracije>
    <SvetiSava></SvetiSava>
    <Galerija2></Galerija2>
  </form>;
}

export default PocetnaStranica;