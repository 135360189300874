import { useEffect, useState } from "react";
import VaznaObavestenja from "../../komponente/Obavestenja/VaznaObavestenja"
import Slike from "../../komponente/Pocetna/Slike"
import Vesti from "../../komponente/Vesti/Vestii"
import apiEndpoint from "../../test/config";
import pozicijeSlika from "../../test/pozicije";
import Slike2 from "../../komponente/Pocetna/Slike2";


function VestiStranica() {
    let query = `{
        post(id: "cG9zdDoyNTM2") {
            glavneSlike {
                vestistranica {
                    naslov
                    shadow
                    drugaslika
                    slikanamobilnom {
                        sourceUrl
                    }
                    zoomdirection
                    deskripcija
                    opcijenaslova
                    glavnaslika {
                        sourceUrl
                    }
                }
            }
        }
        vesti (first: 10000){
            nodes {
                id
                date
                acf {
                    datumobjave
                }
            }
        }
    }`
    const [izgled, setIzgled] = useState([]);
    const [vestiIDs, setVestiIDs] = useState([]);
    const [ucitano, setUcitano] = useState(false)
    const jsonUSlike = (jsonData) => {
        function compareDates(date1, date2) {
            const [dan1, mesec1, godina1] = date1.split('/').map(Number);
            const [dan2, mesec2, godina2] = date2.split('/').map(Number);
        
            if (godina1 !== godina2) {
                return godina1 - godina2;
            } 
            else if (mesec1 !== mesec2) {
                return mesec1 - mesec2;
            } 
            else {
                return dan1 - dan2;
            }
        }

        let opcijeNaslova = jsonData['post']['glavneSlike']['vestistranica']['opcijenaslova'] == null ? ['levo'] : jsonData['post']['glavneSlike']['vestistranica']['opcijenaslova']

        let velNaslova = opcijeNaslova.includes('velikaslova') ? 2 : opcijeNaslova.includes('srednjaslova') ? 1 : 0
        let pozNaslova = opcijeNaslova.includes('levo') ? 0 : opcijeNaslova.includes('desno') ? 2 : opcijeNaslova.includes('centar') ? 1 : 0

        let slika = [
            {
                zatamljenje: jsonData["post"]["glavneSlike"]["vestistranica"]['shadow'] == null ? false : true,
                mobUrl: jsonData["post"]["glavneSlike"]["vestistranica"]['drugaslika'] ? (jsonData["post"]["glavneSlike"]["galerijastranica"]['slikanamobilnom'] == null ? null : jsonData["post"]["glavneSlike"]["galerijastranica"]['slikanamobilnom']['sourceUrl']) : null,
                redniBroj: 0,
                naslov: jsonData["post"]["glavneSlike"]["vestistranica"]["naslov"],
                pozSlika: pozicijeSlika[jsonData["post"]["glavneSlike"]["vestistranica"]["zoomdirection"]],
                opis: jsonData["post"]["glavneSlike"]["vestistranica"]["deskripcija"] == null ? ' ' : jsonData["post"]["glavneSlike"]["vestistranica"]["deskripcija"],
                link_strane: '',
                pozNaslova: pozNaslova,
                velNaslova: velNaslova,
                url: jsonData["post"]["glavneSlike"]["vestistranica"]["glavnaslika"]["sourceUrl"],
            }
        ]
        let vestiArr = jsonData['vesti']['nodes'].map((vest) => {
            return {
                id: vest.id,
                datum: vest.acf.datumobjave == null ? vest.date.match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join("/") : vest.acf.datumobjave
            }
        }).sort((a,b) => compareDates(b.datum, a.datum))
        // console.log(vestiArr)

        setVestiIDs(vestiArr)
        setIzgled(slika)
        setUcitano(true)
    }
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => jsonUSlike(data['data']))
    }, []);
    const params = new URLSearchParams(window.location.search);
    return <>
        <div>
            <Slike2 slike={izgled}></Slike2>
            {ucitano && <Vesti orderedIDs={vestiIDs}></Vesti>}
        </div>
    </>
}

export default VestiStranica