import { useEffect, useState } from "react"
import { FileIcon, defaultStyles } from "react-file-icon"
import { Link } from "react-router-dom"
import ObavestenjeWidget from "./ObavestenjeWidget"
import apiEndpoint from "../../test/config"

function ObavestenjaWidgets({ broj_obavestenja }) {

    const query = `{
        allObavestenja(first: ${broj_obavestenja}) {
          nodes {
            ...ObavestenjaFields
          }
        }
      }
      
      fragment ObavestenjaFields on Obavestenja {
        naslov
        vazno
        date
        teloObavestenja
        fajl {
          mediaItemUrl
          title
        }
      }`

    const dataToVesti = (data) => {
        let obavestenjaArr = data.map((obavestenje, index) => {
            return {
                tekst: obavestenje['teloObavestenja'],
                naslov: obavestenje['naslov'],
                vazno: obavestenje['vazno'],
                datum: obavestenje["date"].match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join("."),
                fajlovi: obavestenje['fajl'] == null ? [] : obavestenje['fajl'].map((fajl) => {
                    var extensions = fajl['mediaItemUrl'].match(/\.([0-9a-z]+)(?:[\?#]|$)/i)
                    var fileExt = extensions.pop()
                    return {
                        ext: fileExt,
                        naslov: fajl['title'],
                        link: fajl['mediaItemUrl']
                    }
                })
            }
        })
        setObavestenja(obavestenjaArr)
        setObavestenjaB(true)
    }
    const [obavestenja, setObavestenja] = useState([])
    const [obavestenjaB, setObavestenjaB] = useState(false)
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => dataToVesti(data['data']['allObavestenja']['nodes']));
    }, []);

    return <>
        <div className="obavestenja-widget">
            <Link to={`/obavestenja`}>Огласна табла</Link>
            <ul className="obavestenja">
                {obavestenjaB && obavestenja.map((obavestenje, index) => {
                    return <ObavestenjeWidget key={index} obavestenje={obavestenje}></ObavestenjeWidget>
                })}
            </ul>
        </div>
    </>
}

export default ObavestenjaWidgets