import { useState } from "react"
import WrapperSidebar from "../WrapperSidebar"
import KolektivTipWidget from "../Widgets/KolektivTipWidget"
import Nastavnici from "../Nastavnici/Nastavnici"
import NeNastavnici from "../Nastavnici/NeNastavnici"
import { Link } from "react-router-dom"

function Kolektiv({ zaposleni, predmeti, predmetiImena, predmetiSlugs, tip = ""}) {
    const [currTip, setCurrTip] = useState(tip == "" ? Object.keys(zaposleni)[0] : tip)

    return <>
        <WrapperSidebar element={<>
            <div className="fon-selector">
                {Object.keys(zaposleni).map((key, index) => {
                    return <li>
                        <Link onClick={() => setCurrTip(key)} to={`/kolektiv?kat=${zaposleni[key].ime}`} className="tip">
                            {zaposleni[key].ime}
                        </Link>
                        {index != Object.keys(zaposleni).length - 1 && <div className="hl"></div>}
                    </li>
                })}
            </div>
            {Object.keys(zaposleni).map((key) => {
                if (key === currTip) {
                    if (zaposleni[key].ime == "Наставник") {
                        console.log(predmeti, predmetiImena)
                        return <Nastavnici zaposleni={zaposleni[key].zaposleni} predmeti={predmeti} predmetiImena={predmetiImena} predmetiSlugs={predmetiSlugs}></Nastavnici>
                    }
                    return <NeNastavnici zaposleni={zaposleni[key].zaposleni} tip={zaposleni[key].ime}></NeNastavnici>
                }
            })}
        </>} vl_slide={false} widgets={[]}  ></WrapperSidebar>
        {/* </>} vl_slide={false} widgets={[<KolektivTipWidget setTip={setCurrTip} tipovi={Object.keys(zaposleni).map((key) => { return { id: key, ime: zaposleni[key].ime } })}></KolektivTipWidget>]}></WrapperSidebar> */}
    </>
}

export default Kolektiv