import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import WrapperSidebar from "../../komponente/WrapperSidebar"
import VestiWidget from "../../komponente/Widgets/VestiWidget"
import ObavestenjaWidgets from "../../komponente/Widgets/ObavestenjaWidgets"
import Slike from "../../komponente/Pocetna/Slike"
import SekcijaVremeWidget from "../../komponente/Widgets/SekcijaVremeWidget"
import OstaleSekcije from "../../komponente/Sekcije/OstaleSekcije"
import SekcijeNastavniciWidget from "../../komponente/Widgets/SekcijeNastavniciWidget"
import apiEndpoint from "../../test/config"
import Slike2 from "../../komponente/Pocetna/Slike2"
import zameniGalerije from "../../test/zamenaGalerije"
import LinkoviWidget from "../../komponente/Widgets/LinkoviWidget"
import MotoWidget from "../../komponente/Widgets/MotoWidget"

function SekcijaStranica() {
  let { id } = useParams()
  console.log(id)

  const query = `{
        sekcijaBy(id: "${id}") {
          sekcija {
            citat
            ime
            textbox
            drugaslika
            shadow
            slikanamobilnom {
              sourceUrl
            }
            opcijenaslova
            vremeodrzavanja 
            velikaslika {
              sourceUrl
            }
            malaslika {
              sourceUrl
            }
            nastavnik {
              ... on Kolektiv {
                id
                nastavnik {
                  imeprezime
                  slika {
                    sourceUrl
                  }
                }
              }
            }
            sidebar {
              vremesekcije
              tip
              krataktekst
            }
          }
        }
      }`

  const dataToVesti = (data) => {
    let sidebar = data['sidebar']
    let tipovi = sidebar['tip']
    let widgets = []


    if (tipovi != null) {

      if (!tipovi.includes('Ништа')) {
        if (tipovi.includes('Време одржавања')){
          widgets.push(<SekcijaVremeWidget vremeodrzavanja={sidebar['vremesekcije']}></SekcijaVremeWidget>)
        }
        if (tipovi.includes('Линкови')) {
          let linkovi_arr = []
          for (let i = 1; i <= 6; i++) {
            linkovi_arr.push(sidebar['brzilinkovi'][`brzilink${i}`])
          }
          linkovi_arr = linkovi_arr.map((x) => {
            return {
              link: x['link'] == null ? null : x['link']['url'],
              naslov: x['link'] == null ? null : x['link']['title'],
              slika: x['slika'] == null ? null : x['slika']['sourceUrl']
            }
          })
          widgets.push(<LinkoviWidget linkovi_={linkovi_arr}></LinkoviWidget>)
        }
        if (tipovi.includes('Мото или Текст')) {
          let tekst = sidebar['krataktekst']
          widgets.push(<MotoWidget tekst={tekst}></MotoWidget>)
        }
        if (tipovi.includes('Новости')) {
          widgets.push(<VestiWidget broj_vesti={3}></VestiWidget>)
        }
        if (tipovi.includes('Обавештења')) {
          widgets.push(<ObavestenjaWidgets broj_obavestenja={3}></ObavestenjaWidgets>)
        }
      }
    }
    console.log(widgets, sidebar, tipovi)
    let opcijeNaslova = data['opcijenaslova'] == null ? ['levo'] : data['opcijenaslova']
    let velNaslova = opcijeNaslova.includes('velikaslova') ? 2 : opcijeNaslova.includes('srednjaslova') ? 1 : 0
    let pozNaslova = opcijeNaslova.includes('levo') ? 0 : opcijeNaslova.includes('desno') ? 2 : opcijeNaslova.includes('centar') ? 1 : 0

    let text = data['textbox']
    
    text = zameniGalerije(text)

    let sekcijaData = {
      ime: data['ime'],
      citat: data['citat'] == null ? null : data['citat'],
      textbox: text,
      widgets: widgets,
      vremeodrzavanja: data['vremeodrzavanja'],
      zatamljenje: data['shadow'] == null ? false : true,
      mobUrl: data['drugaslika'] ? (data['slikanamobilnom'] == null ? null : data['slikanamobilnom']['sourceUrl']) : null,
      velikaslika: data['velikaslika']['sourceUrl'],
      malaslika: data['malaslika']['sourceUrl'],
      nastavnici: data['nastavnik'] && data['nastavnik'].map((x) => {
        return {
          id: x['id'],
          ime: x['nastavnik']['imeprezime'],
          slika: x['nastavnik']['slika'] == null ? null : x['nastavnik']['slika']['sourceUrl']
        }
      }),
      pozNaslova: pozNaslova,
      velNaslova: velNaslova
    }
    //console.log(sekcijaData)
    setSekcija(sekcijaData)
    setUcitano(true)
  }
  const [sekcija, setSekcija] = useState({})
  const [ucitano, setUcitano] = useState(false)
  useEffect(() => {
    fetch(apiEndpoint,
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          query
        })
      }).then((response) => response.json()).then((data) => dataToVesti(data['data']['sekcijaBy']['sekcija']));
  }, []);

  return <>
    <div className="sekcija-wrapper">
      <Slike2 slike={ucitano && [
        {
          zatamljenje: sekcija['zatamljenje'],
          mobUrl: sekcija['mobUrl'],
          redniBroj: 0,
          naslov: sekcija['ime'],
          pozNaslova: sekcija['pozNaslova'],
          velNaslova: sekcija['velNaslova'],
          opis: "",
          link_strane: '',
          url: sekcija['velikaslika'],
        }
      ]}></Slike2>
      <WrapperSidebar element={ucitano && <>
        <div className="sekcija">
          {sekcija['citat'] != null && <>
            <h1>{`${sekcija['citat']}`}</h1>
            <div className="hl">
              <div></div>
            </div>
          </>}
          {sekcija['nastavnici'] != null && <>
            <div className="nastavnici">
              <h2>Наставници :</h2>
              <ul>
                {sekcija['nastavnici'].map((nastavnik) => {
                  return <Link to={`/kolektiv/${nastavnik.id}`}>{nastavnik.ime}</Link>
                })}
              </ul>
            </div>
          </>}
          <div className="vreme">
            {sekcija['vremeodrzavanja'] != null && <h2>Време одржавања :</h2>}
            {sekcija['vremeodrzavanja'] != null && <ul> {sekcija['vremeodrzavanja'].split('\r\n').map((x) => { return <p>{x}</p> })}</ul>}
          </div>
          <div className="test" dangerouslySetInnerHTML={{ __html: sekcija['textbox'] }}></div>
          <OstaleSekcije></OstaleSekcije>
        </div>
      {/* </>} vl_slide={true} widgets={[<SekcijaVremeWidget vremeodrzavanja={sekcija['vremeodrzavanja'] != null ? sekcija['vremeodrzavanja'] : ''}></SekcijaVremeWidget>]}></WrapperSidebar> */}
      </>} vl_slide={true} widgets={sekcija['widgets']}></WrapperSidebar>
    </div>
  </>
}

export default SekcijaStranica