import { useState, useEffect } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function CircleSwiper({ naslov, slikeImenaLinkovi, sviLink }) {
  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    // Update slidesPerView based on screen width
    const updateSlidesPerView = () => {
      if (window.innerWidth >= 1200) {
        setSlidesPerView(5);
      }
      else if (window.innerWidth >= 700) {
        setSlidesPerView(4);
      } else if (window.innerWidth >= 500) {
        setSlidesPerView(3);
      } else if (window.innerWidth >= 300) {
        setSlidesPerView(2);
      } else {
        setSlidesPerView(1);
      }
    };

    // Initial setup
    updateSlidesPerView();

    // Listen for window resize events
    window.addEventListener('resize', updateSlidesPerView);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateSlidesPerView);
    };
  }, []);

  return (
    <>
      <div className="circleSwiper-wrapper">
        <div className="naslov">
          <h1>{naslov}</h1>
        </div>
        <div className="swiper-wrapper">
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {slikeImenaLinkovi.map((x, index) => (
              <SwiperSlide key={index}>
                <div>
                  <img src={x.slika} alt={`Slide ${index + 1}`} />
                  <a className="ime" href={x.link}>
                    {x.ime}
                  </a>
                  <a className="link" href={x.link}></a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="donji-deo">
          <div>
            <a className="dugme" href={sviLink.link}>
              {sviLink.tekst}
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default CircleSwiper;
