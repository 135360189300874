// import axios from "axios"
import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import apiEndpoint from "../../test/config"
import { motion, useScroll, useSpring, useTransform } from "framer-motion"
import { useMediaQuery } from "react-responsive"


function Vesti() {
    const query = `{
        vesti (first: 10000){
            nodes {
                id
                date
                acf {
                    datumobjave
                }
            }
        }
    }`

    const vestiQuery = `{
        vest(id: "<id>") {
            id
            acf {
              podnaslov
              naslov
              datumobjave
              telovesti
              thumbnail {
                sourceUrl
              }
            }
            date
          }
      }`

    const mesec = (datum) => {
        switch (datum) {
            case '01':
                return 'Јануар'
            case '02':
                return 'Фебруар'
            case '03':
                return 'Март'
            case '04':
                return 'Април'
            case '05':
                return 'Мај'
            case '06':
                return 'Јун'
            case '07':
                return 'Јул'
            case '08':
                return 'Август'
            case '09':
                return 'Септембар'
            case '10':
                return 'Октобар'
            case '11':
                return 'Новембар'
            default:
                return 'Децембар'
        }
    }

    const dataToVesti = (vest) => {
        let acf = vest['acf']
        let vestData = {
            id: vest['id'],
            naslov: acf['naslov'],
            podnaslov: acf['podnaslov'],
            tekst: acf['telovesti'],
            slika: acf['thumbnail']['sourceUrl'],
            izabranidatum: acf['datumobjave'] == null ? vest["date"].match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join(".") : acf['datumobjave'].split("/").join("."),
            datum: vest["date"].match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join(".")
        }
        return vestData
    }
    const [vesti, setVesti] = useState([])
    const [vestiB, setVestiB] = useState(false)
    function compareDates(date1, date2) {
        const [dan1, mesec1, godina1] = date1.split('/').map(Number);
        const [dan2, mesec2, godina2] = date2.split('/').map(Number);

        if (godina1 !== godina2) {
            return godina1 - godina2;
        }
        else if (mesec1 !== mesec2) {
            return mesec1 - mesec2;
        }
        else {
            return dan1 - dan2;
        }
    }
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            })
            .then((response) => response.json())
            .then((jsonData) => {
                let vestiArr = jsonData['data']['vesti']['nodes'].map((vest) => {
                    return {
                        id: vest.id,
                        datum: vest.acf.datumobjave == null ? vest.date.match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join("/") : vest.acf.datumobjave
                    }
                }).sort((a, b) => compareDates(b.datum, a.datum))
                return vestiArr.slice(0, 5)
            })
            .then((orderedIDs) => {
                let queries = orderedIDs.map((ord) => { return vestiQuery.replace(/<id>/, ord.id) });
                const newData = [];
                // console.log(queries)

                Promise.all(
                    queries.map(queryStr =>
                        fetch(apiEndpoint, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            body: JSON.stringify({ query: queryStr })
                        }).then(response => response.json())
                    )
                ).then(dataArray => {
                    //console.log(dataArray)
                    dataArray.forEach(data => {
                        newData.push(data.data.vest);
                    });

                    const updatedVesti = [...vesti];
                    newData.forEach(vest => {
                        const vestData = dataToVesti(vest);
                        updatedVesti.push(vestData);
                    });
                    setVesti(updatedVesti);
                    setVestiB(true)
                });
            })
    }, []);

    function indexToAnimation(index){
        switch (index) {
            case 0:
                return 'hidden blur left'
            case 1:
                return 'hidden blur left'
            case 2:
                return 'hidden blur right'
            case 3:
                return 'hidden blur right'
            case 4:
                return 'hidden blur right'
            default:
                return 'hidden blur'
        }
    }

    const ref1 = useRef(null)
    const { scrollYProgress: scrlProgress1 } = useScroll({
        target: ref1,
        offset: ['1 0.1', '1 1']
    })
    const ref2 = useRef(null)
    const { scrollYProgress: scrlProgress2 } = useScroll({
        target: ref2,
        offset: ['1 0.1', '1 1']
    })

    const scaleX1 = useSpring(scrlProgress1, {
        damping: 30,
        stiffness: 120,
        restDelta: 0.001
    })
    const scaleX2 = useSpring(scrlProgress2, {
        damping: 30,
        stiffness: 120,
        restDelta: 0.001
    })

    const width1 = useTransform(scaleX1, [1, 0.1], ['0px', '1240px'])
    const width1M = useTransform(scaleX1, [1, 0.1], ['0%', '95%'])
    const width2 = useTransform(scaleX2, [1, 0.1], ['0px', '1240px'])
    const width2M = useTransform(scaleX2, [1, 0.1], ['0%', '95%'])
    const opacity = useTransform(scaleX1, [1, 0.9, 0.1], ['0%', '0%', '100%'])

    const isResponsive = useMediaQuery({ query: '(max-width: 1260px)' })
    const isMobile = useMediaQuery({ query: '(max-width: 910px)' })

    return <>
        <div className="vesti-wrapper">
            <h1 className="hidden left blur">Гимназијске координате</h1>
            <motion.h2
            style={{
                width: isMobile ? '95%' : isResponsive ? width1M : width1,
                opacity: isMobile ? '100%' : opacity
            }}>(x,y,z,t)</motion.h2>
            <motion.div
            ref={ref1}
            style={{
                width: isMobile ? '95%' : isResponsive ? width1M : width1
            }}
            className="line"></motion.div>
            <div className="vesti-prikaz">
                {vestiB && <>
                    {vesti.slice(0, vesti.length > 5 ? 5 : vesti.length).map((vest, index) => {
                        return <div className={`${index == 0 || index == 1 ? "glavna vest" : "vest"} ${indexToAnimation(index)}`} id={`vest${index + 1}`}>
                            <div className="slika"><img src={vest['slika']}></img></div>
                            <Link className='cover' to={`/vesti/${vest['id']}`}></Link>
                            <Link className='naslov' to={`/vesti/${vest['id']}`}>{vest['naslov']}</Link>
                            <p className="datum">{vest['izabranidatum'].substring(0, 2) + '. ' + mesec(vest['izabranidatum'].substring(3, 5)) + ' ' + vest['izabranidatum'].substring(6, 10) + '.'}</p>
                            <p className="tekst" onLoad={() => alert('test')}>{vest['podnaslov']}</p>
                            <div className="hl"></div>
                        </div>
                    })}
                </>}
            </div>
            <motion.div
            ref={ref2}
            style={{
                width: isMobile ? '95%' : isResponsive ? width2M : width2
            }}
            className="line l2"></motion.div>
            <div className="donji-deo">
                <Link to="vesti" className="sve-galerije" onClick={() => { window.scrollTo(0, 0) }}>Све вести...</Link>
            </div>
        </div>
    </>
}

export default Vesti