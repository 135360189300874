import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import apiEndpoint from "../../test/config"

function VestiWidget({ broj_vesti }) {
    const query = `{
        vesti (first: 10000){
            nodes {
                id
                date
                acf {
                    datumobjave
                }
            }
        }
    }`

    const vestiQuery = `{
        vest(id: "<id>") {
            id
            acf {
              podnaslov
              naslov
              datumobjave
              telovesti
              thumbnail {
                sourceUrl
              }
            }
            date
          }
      }`

      const dataToVesti = (vest) => {
        let acf = vest['acf']
        let vestData = {
            id: vest['id'],
            naslov: acf['naslov'],
            podnaslov: acf['podnaslov'],
            tekst: acf['telovesti'],
            slika: acf['thumbnail']['sourceUrl'],
            izabranidatum: acf['datumobjave'] == null ? vest["date"].match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join(".") : acf['datumobjave'].split("/").join("."),
            datum: vest["date"].match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join(".")
        }
        return vestData
    }
    const [vesti, setVesti] = useState([])
    const [vestiB, setVestiB] = useState(false)
    useEffect(() => {
        function compareDates(date1, date2) {
            const [dan1, mesec1, godina1] = date1.split('/').map(Number);
            const [dan2, mesec2, godina2] = date2.split('/').map(Number);

            if (godina1 !== godina2) {
                return godina1 - godina2;
            }
            else if (mesec1 !== mesec2) {
                return mesec1 - mesec2;
            }
            else {
                return dan1 - dan2;
            }
        }
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            })
            .then((response) => response.json())
            .then((jsonData) => {
                let vestiArr = jsonData['data']['vesti']['nodes'].map((vest) => {
                    return {
                        id: vest.id,
                        datum: vest.acf.datumobjave == null ? vest.date.match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join("/") : vest.acf.datumobjave
                    }
                }).sort((a, b) => compareDates(b.datum, a.datum))
                return vestiArr.slice(0, 5)
            })
            .then((orderedIDs) => {
                let queries = orderedIDs.map((ord) => { return vestiQuery.replace(/<id>/, ord.id) });
                const newData = [];
                // console.log(queries)

                Promise.all(
                    queries.map(queryStr =>
                        fetch(apiEndpoint, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            },
                            body: JSON.stringify({ query: queryStr })
                        }).then(response => response.json())
                    )
                ).then(dataArray => {
                    //console.log(dataArray)
                    dataArray.forEach(data => {
                        newData.push(data.data.vest);
                    });

                    const updatedVesti = [...vesti];
                    newData.forEach(vest => {
                        const vestData = dataToVesti(vest);
                        updatedVesti.push(vestData);
                    });
                    setVesti(updatedVesti);
                    setVestiB(true)
                });
            })
    }, []);

    const mesec = (datum) => {
        switch (datum) {
            case '01':
                return 'јануар'
            case '02':
                return 'фебруар'
            case '03':
                return 'март'
            case '04':
                return 'април'
            case '05':
                return 'мај'
            case '06':
                return 'јун'
            case '07':
                return 'јул'
            case '08':
                return 'август'
            case '09':
                return 'септембар'
            case '10':
                return 'октобар'
            case '11':
                return 'новембар'
            default:
                return 'децембар'
        }
    }

    return <>
        <div className="vest-widget">
            <Link to="/vesti">
                <h1>Гимназијске вести</h1>
            </Link>
            <div className="vesti">
                {vestiB && vesti.slice
                    (0, broj_vesti)
                    .map((vest, index) => {
                        return <>
                            <div className="vest">
                                <div className="slika">
                                    <img src={vest['slika']}></img>
                                    <a href={`${window.location.origin}/vesti/${vest.id}`}></a>
                                </div>
                                <div className="naslov-datum">
                                    <div className="naslov">
                                        <a href={`${window.location.origin}/vesti/${vest.id}`}>{vest['naslov']}</a>
                                    </div>
                                    <div className="datum">
                                        <h2>{vest['izabranidatum'].substring(0, 2) + '. ' + mesec(vest['izabranidatum'].substring(3, 5)) + ' ' + vest['izabranidatum'].substring(6, 10) + '.'}</h2>
                                    </div>
                                </div>
                            </div>
                        </>
                    })}
            </div>
        </div>
    </>
}

export default VestiWidget