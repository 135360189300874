import { useEffect, useState } from "react"
import CircleSwiper from "../CircleSwiper"
import apiEndpoint from "../../test/config"
import slika_url from '../../res/images/Default.png'

function OstaloOsoblje() {
    const query = `{
        zaposleni {
          nodes {
            id
            nastavnik {
              imeprezime
              slika {
                sourceUrl
              }
            }
          }
        }
      }`

    const dataToVesti = (data) => {
        //console.log(data)
        let vestiArr = data.map((zaposljen, index) => {
            let nastavnik = zaposljen['nastavnik']
            return {
                ime: nastavnik['imeprezime'],
                slika: nastavnik['slika'] == null ? slika_url : nastavnik['slika']['sourceUrl'],
                id: zaposljen['id']
            }
        })
        //console.log(vestiArr)
        setZaposleni(vestiArr)
        setUcitano(true)
    }
    const [zaposleni, setZaposleni] = useState([])
    const [ucitano, setUcitano] = useState(false)
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => dataToVesti(data['data']['zaposleni']['nodes']));
    }, []);

    return <>
        <div className="ostalo-osoblje-wrapper">
            {ucitano && <CircleSwiper
                naslov='Остало особље'
                sviLink={{
                    tekst: 'Назад на наставнике',
                    link: `${window.location.origin}/kolektiv`
                }}
                slikeImenaLinkovi={zaposleni.map((x) => {
                    return {
                        slika: x['slika'],
                        ime: x['ime'],
                        link: `${window.location.origin}/kolektiv/${x.id}`
                    }
                })}
            ></CircleSwiper>}
        </div>
    </>
}

export default OstaloOsoblje