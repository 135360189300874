import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { Link } from "react-router-dom"
import slika_url from '../../res/images/Default.png'

function Nastavnici({ zaposleni, predmeti, predmetiImena, predmetiSlugs }) {
    predmeti.sort((a,b) => {return [predmetiSlugs[a] - predmetiSlugs[b]]})
    const [IPredmet, setIPredmet] = useState(predmeti[0])
    const [dropdown, setDropdown] = useState(false)

    let predmetiEl = useRef(null)

    let findIndex = (predmetId) => {
        for (let i = 0; i < predmeti.length; i++) {
            if (predmeti[i] === predmetId) {
                return i;
            }
        }
        return -1;
    }

    return <>
        <div className="nastavnici-wrapper">
            <div className="predmet-select-wrapper">
                {/* <h1>{dropdown ? 'true' : 'false'}</h1> */}
                <div className="izabrani" onClick={() => {
                    setDropdown(!dropdown);
                }} style={{ cursor: 'pointer' }}>
                    <h1>{predmetiImena[IPredmet]}</h1>
                    <FontAwesomeIcon icon={faChevronDown} id={dropdown ? 'rotacija' : ''} />
                </div>
                <ul className="predmeti" id={dropdown ? 'visible' : 'invisible'}>
                    <div>
                        {predmeti.map((predmet) => {
                            return <li onClick={() => { setIPredmet(predmet); setDropdown(!dropdown); window.scrollTo(0, 300) }} style={{ cursor: 'pointer' }} id={predmet == IPredmet ? 'izabran' : ''}>{predmetiImena[predmet]}</li>
                        })}
                    </div>
                </ul>
            </div>
            <div className="layout">
                {zaposleni.map((zaposljen) => {
                    if (zaposljen == null || !zaposljen['predmeti'].includes(IPredmet)) return <></>
                    return <>
                        <div>
                            <div>
                                <img src={zaposljen['slika'] ? zaposljen['slika'] : slika_url}></img>
                                <Link to={`/kolektiv/${zaposljen['id']}`}></Link>
                            </div>
                            <Link to={`/kolektiv/${zaposljen['id']}`}>{zaposljen['ime']}</Link>
                        </div>
                    </>
                })}
            </div>
        </div>
    </>
}

export default Nastavnici