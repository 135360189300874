import { useEffect, useState } from "react"
import Dokumenta from "../../komponente/JavnaDokumenta/Dokumenta"
import Slike from "../../komponente/Pocetna/Slike"
import VestiWidget from "../../komponente/Widgets/VestiWidget"
import WrapperSidebar from "../../komponente/WrapperSidebar"
import apiEndpoint from "../../test/config"
import pozicijeSlika from "../../test/pozicije"
import Slike2 from "../../komponente/Pocetna/Slike2"

function DokumentaStranica() {
    let query = `{
        post(id: "cG9zdDoyNTM2") {
          glavneSlike {
            dokumentistranica {
              deskripcija
              naslov
              shadow
              drugaslika
              slikanamobilnom {
                sourceUrl
              }
              opcijenaslova
              zoomdirection
              glavnaslika {
                sourceUrl
              }
            }
          }
        }
      }`
    const [izgled, setIzgled] = useState([]);
    const jsonUSlike = (jsonData) => {
        let opcijeNaslova = jsonData['post']['glavneSlike']['dokumentistranica']['opcijenaslova'] == null ? ['levo'] : jsonData['post']['glavneSlike']['dokumentistranica']['opcijenaslova']
        let velNaslova = opcijeNaslova.includes('velikaslova') ? 2 : opcijeNaslova.includes('srednjaslova') ? 1 : 0
        let pozNaslova = opcijeNaslova.includes('levo') ? 0 : opcijeNaslova.includes('desno') ? 2 : opcijeNaslova.includes('centar') ? 1 : 0

        let slika = [
            {
                zatamljenje: jsonData["post"]["glavneSlike"]["dokumentistranica"]['shadow'] == null ? false : true,
                mobUrl: jsonData["post"]["glavneSlike"]["dokumentistranica"]['drugaslika'] ? (jsonData["post"]["glavneSlike"]["galerijastranica"]['slikanamobilnom'] == null ? null : jsonData["post"]["glavneSlike"]["galerijastranica"]['slikanamobilnom']['sourceUrl']) : null,
                redniBroj: 0,
                naslov: jsonData["post"]["glavneSlike"]["dokumentistranica"]["naslov"],
                pozSlika: pozicijeSlika[jsonData["post"]["glavneSlike"]["dokumentistranica"]["zoomdirection"]],
                opis: jsonData["post"]["glavneSlike"]["dokumentistranica"]["deskripcija"] === null ? ' ' : jsonData["post"]["glavneSlike"]["dokumentistranica"]["deskripcija"],
                link_strane: '',
                pozNaslova: pozNaslova,
                velNaslova: velNaslova,
                url: jsonData["post"]["glavneSlike"]["dokumentistranica"]["glavnaslika"]["sourceUrl"],
            }
        ]
        setIzgled(slika);
    }
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => jsonUSlike(data['data']))
    }, []);
    let slika = [
        {
            redniBroj: 0,
            naslov: "Јавна документа",
            opis: "",
            link_strane: '',
            url: 'https://wp.gimnazijasvetisava.edu.rs/wp-content/uploads/2023/12/novazgrada.jpg',
        }
    ]

    const [widgets, setWidgets] = useState([])
    useEffect(() => {
        fetch(apiEndpoint, {
            method: 'POST', headers: { "Content-Type": 'application/json', "Accept": 'application/json' },
            body: JSON.stringify({
                query: `{pages(where: {name: "SideBar"}) {nodes {sidebar {
                        dokumenti
                }}}}` })
        }).then((response) => response.json()).then((data) => {
            setWidgets(data['data']['pages']['nodes'][0]['sidebar']['dokumenti'])
        });
    }, []);

    return <>
        <Slike2 slike={izgled}></Slike2>
        <div className="sekcije-margin">
            <WrapperSidebar element={<>
                <Dokumenta></Dokumenta>
            </>}
                vl_slide={true}
                widget_list={widgets}></WrapperSidebar>
        </div>
    </>
}

export default DokumentaStranica