import '../../style.css'
function Loading() {
    const Url = window.location.href;
    return (
        <div className="LoaderWrapper">
            <h1>Учитавам страницу </h1>
            <div className='spinner'></div>
        </div>
    );
}
export default Loading;