import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Slike from "../../komponente/Pocetna/Slike"
import WrapperSidebar from "../../komponente/WrapperSidebar"
import VestiWidget from "../../komponente/Widgets/VestiWidget"
import WSPGallery from "../../komponente/EksterneKomponente/WSPGallery"
import apiEndpoint from "../../test/config"
import Slike2 from "../../komponente/Pocetna/Slike2"

function GalerijaStranica() {
    let { id } = useParams()
    let query = `{
        fotoGalerijaBy(id: "${id}") {
            naslov
            glavnaSlika {
            sourceUrl
            }
            galleryImages {
            sourceUrl
            }
        }
    }`

    const [widgets, setWidgets] = useState([])
    useEffect(() => {
        fetch(apiEndpoint, {
            method: 'POST', headers: { "Content-Type": 'application/json', "Accept": 'application/json' },
            body: JSON.stringify({
                query: `{pages(where: {name: "SideBar"}) {nodes {sidebar {
                    fotogallery
                }}}}` })
        }).then((response) => response.json()).then((data) => {
            setWidgets(data['data']['pages']['nodes'][0]['sidebar']['fotogallery'])
        });
    }, []);

    const dataToVesti = (data) => {
        let galerijaData = {
            ime: data['naslov'] || '',
            slika: data['glavnaSlika'] && data['glavnaSlika']['sourceUrl'] ? data['glavnaSlika']['sourceUrl'] : '',
            slike: data['galleryImages'] && Array.isArray(data['galleryImages']) ? data['galleryImages'].map((slika) => {
                return slika && slika['sourceUrl'] ? { url: slika['sourceUrl'] } : null;
            }).filter(s => s !== null) : [],
            tekst: data['textGalerije'] || ''
        }
        setGalerija(galerijaData)
        setUcitano(true)
    }
    const [galerija, setGalerija] = useState({})
    const [ucitano, setUcitano] = useState(false)
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => {
                dataToVesti(data['data']['fotoGalerijaBy'])
            });
    }, []);

    let slika = () => {
        return {
            redniBroj: 0,
            naslov: galerija['naslov'],
            opis: "sdsd",
            link_strane: '',
            url: galerija['slika'],
        }
    }

    return <>
        <Slike2 slike={ucitano && [
            {
                zatamljenje: true,
                redniBroj: 0,
                naslov: galerija['ime'],
                opis: '',
                link_strane: '',
                url: galerija['slika'],
            }
        ]}></Slike2>
        <div style={{ marginTop: '100px' }}>
            <WrapperSidebar
                element={<>
                    {ucitano &&
                        <div className="galerija-wrapper2">
                            <WSPGallery galleryImages={galerija['slike'].map((slika) => { return { img: slika['url'] } })}></WSPGallery>
                        </div>
                    }
                </>}
                widget_list={widgets}
                vl_slide={true}></WrapperSidebar>
        </div>
    </>
}

export default GalerijaStranica