import { useEffect, useState } from "react"
import CircleSwiper from "../CircleSwiper"
import apiEndpoint from "../../test/config"
const slika_url = 'https://picsum.photos/1920/1080'

function OstaleSekcije() {
    let query = `{
        sekcije {
        nodes {
            id
            sekcija {
            malaslika {
                sourceUrl
            }
            ime
            }
        }
        }
    }`

    const dataToVesti = (data) => {
        let sekcijaData = data.map((sekcija) => {
            return {
                id: sekcija['id'],
                ime: sekcija['sekcija']['ime'],
                slika: sekcija['sekcija']['malaslika']['sourceUrl']
            }
        })
        setSekcije(sekcijaData)
        setUcitano(true)
    }
    const [sekcije, setSekcije] = useState([])
    const [ucitano, setUcitano] = useState(false)
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => {
                dataToVesti(data['data']['sekcije']['nodes'])
            });
    }, []);

    return <>
        <div className="ostalo-osoblje-wrapper">
            {ucitano && <CircleSwiper
                naslov='Остале секције'
                sviLink={{
                    tekst: 'Све секције',
                    link: 'http://localhost:3000/kolektiv'
                }}
                slikeImenaLinkovi={sekcije.map((x) => {
                    return {
                        slika: x['slika'],
                        ime: x['ime'],
                        link: `${window.location.origin}/sekcija/${x.id}`
                    }
                })}
            ></CircleSwiper>}
        </div>
    </>
}

export default OstaleSekcije