function ZaposljenInfo({naslov, tekst, wysiwyg=false, hl=true}){
    return <>
        <div className="zaposljen-info">
            <h3 className="naslov">{naslov}</h3>
            {wysiwyg ? <div className="tekstdiv" dangerouslySetInnerHTML={{__html : tekst}}></div> : <p className="tekst">{tekst}</p>}
            {hl && <div className="hl"></div>}
        </div>
    </>
}

export default ZaposljenInfo