
function SekcijaVremeWidget(vremeodrzavanja){
    return <>
        <div className="sekcijavreme-wrapper">
            <ul className="tabela">
                <div className="naslov"><h1>Време одржавања</h1></div>
                {vremeodrzavanja['vremeodrzavanja'].split('\r\n').map((x) => {
                    function splitStringAtFirstColon(inputString) {
                        const index = inputString.indexOf(':');
                        
                        if (index === -1) {
                            return [inputString, ''];
                        } else {
                            const before = inputString.slice(0, index);
                            const after = inputString.slice(index + 1);
                            return [before, after];
                        }
                    }
                    let [levo, desno] = splitStringAtFirstColon(x)
                    if (desno == '') return <div className="vreme"><p>{x}</p></div>
                    else return <div className="vreme" id="dvetacke">
                        <p>{levo}</p>
                        <h2>{desno}</h2>
                    </div>
                })}
            </ul>
        </div>
    </>
}

export default SekcijaVremeWidget