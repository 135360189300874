import { useEffect, useState } from 'react';
import beliLogo from '../res/images/footer/beliLogo.svg';
import lokacija from '../res/images/footer/lokacija.svg';
import mail from '../res/images/footer/mail.svg';
import telefon from '../res/images/footer/telefon.svg';
import apiEndpoint from '../test/config';
import { useAtom } from 'jotai';
import { logoAtom } from '../GlobalState/logoAtom';

let ret = {};

function izbrisiCvor(dict, cvor) {
    Object.keys(dict).forEach((key) => {
        if (key == cvor) {
            ret = dict[key];
            delete dict[key];
            return;
        }
        izbrisiCvor(dict[key], cvor);
    })
}

function ubaciCvor(dict, parent, child) {
    Object.keys(dict).forEach((key) => {
        if (key == parent) {
            dict[key][child] = ret;
            return;
        }
        ubaciCvor(dict[key], parent, child);
    })
}

function napraviDrvo(dict, pairs) {
    for (let i = 0; i < pairs.length; i++) {
        ret = {};
        izbrisiCvor(dict, pairs[i][1])
        ubaciCvor(dict, pairs[i][0], pairs[i][1]);
    }
}

function GenerisiMeni(jsonData) {
    let konekcijeDict = {}
    let itemNames = {};
    let itemLinks = {}
    try {
        let items = jsonData['menus']['nodes'][0]['menuItems']['edges'].map(x => [x['node']['menuItemId'], x['node']['label'], x['node']['url']])
        for (let i = 0; i < items.length; i++) itemNames[items[i][0]] = items[i][1];
        for (let i = 0; i < items.length; i++) {
            let link = items[i][2][0] == '/' ? `${window.location.origin}${items[i][2]}` : items[i][2];
            itemLinks[items[i][0]] = link;
        }
        let konekcijeArr = jsonData['menus']['nodes'][0]['menuItems']['nodes'].map(x => x = x['childItems']['nodes']);
        let konekcijePairs = []
        for (let i = 0; i < konekcijeArr.length; i++) {
            for (let j = 0; j < konekcijeArr[i].length; j++) {
                konekcijePairs.push([konekcijeArr[i][j]['parentDatabaseId'], konekcijeArr[i][j]['databaseId']]);
            }
        }
        for (let i = 0; i < items.length; i++) konekcijeDict[items[i][0]] = {};
        napraviDrvo(konekcijeDict, konekcijePairs);
    }
    catch (error) { return <li key={1}></li> }

    let items = []
    let id = 0;
    Object.keys(konekcijeDict).forEach((key, i) => {
        let w = Math.max(...Object.keys(konekcijeDict[key]).map(x => itemNames[x].length));
        id++;
        items.push(<>
            <li key={i}>
                <a href={itemLinks[key]}>{`${itemNames[key]}`}</a>
            </li>
        </>)
    })
    return items;
}

function Footer() {
    const [logo, setLogo] = useAtom(logoAtom)

    const query = `
    {
        menus(where: {location: HCMS_MENU_FOOTER}) {
          nodes {
            menuItems(first: 100) {
              edges {
                node {
                  url
                  label
                  menuItemId
                }
              }
              nodes {
                childItems(first: 100) {
                  nodes {
                    id
                    databaseId
                    parentDatabaseId
                    title
                  }
                }
              }
            }
          }
        }
      }`;
    const [jsonData, setJsonData] = useState([]);

    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => setJsonData(data['data']));
    }, []);

    return <>
        <div className="footer-wrapper">
            <div className="footer-content">
                <div className="logo-wrapper">
                    <img src={logo['beli_logo']}></img>
                    <div className="hl"></div>
                </div>

                <div className="kontakt-wrapper">
                    <div id="lokacija">
                        <img src={lokacija}></img>
                        <p>Ресавска 58, 11000 Београд</p>
                    </div>
                    <div id="mail">
                        <img src={mail}></img>
                        <p>gimnazija@sveti-sava.edu.rs</p>
                    </div>
                    <div id="telefon">
                        <img src={telefon}></img>
                        <p>011/2658-921</p>
                    </div>
                    <div className="hl"></div>
                </div>

                <div className='brzi-linkovi-wrapper'>
                    <h1>Брзи линкови :</h1>
                    <div className="hl"></div>
                    <ul>
                        {GenerisiMeni(jsonData)}
                    </ul>
                </div>

                <div className='iframe-wrapper'>
                    <iframe
                        scrolling="no"
                        marginHeight={0}
                        marginWidth={0}
                        src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Gymnasium%20Saint%20Sava,%20Resavska,%20Belgrade+(%D0%93%D0%B8%D0%BC%D0%BD%D0%B0%D0%B7%D0%B8%D1%98%D0%B0%20%D0%A1%D0%B2%D0%B5%D1%82%D0%B8%20%D0%A1%D0%B0%D0%B2%D0%B0)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                        width="100%"
                        height="100%"
                        frameBorder={0}>
                        <a href="https://www.maps.ie/distance-area-calculator.html">area maps</a>
                    </iframe>
                </div>

                <div className="copyright-wrapper">
                    <div className='hl'></div>
                    <div className='text'>
                        <p>Сва права задржана</p>
                        <a href={`${window.location.origin}/autorska-prava`}> @Гимазија „Свети Сава” 2023</a>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Footer