import Reveal from '../../animacije/FramerMotion/Reveal';
import { NavigacijaWidget } from '../Widgets/NavigacijaWidget';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import { Link } from 'react-router-dom';

let pozSlikePoID = []
pozSlikePoID[0] = 'levo'
pozSlikePoID[1] = 'centar'
pozSlikePoID[2] = 'desno'

let pozNaslovaPoID = []
pozNaslovaPoID[0] = 'levo'
pozNaslovaPoID[1] = 'centar'
pozNaslovaPoID[2] = 'desno'

let velNaslovaPoID = []
velNaslovaPoID[0] = 'mali'
velNaslovaPoID[1] = 'srednji'
velNaslovaPoID[2] = 'veliki'

function Slike2({ slike }) {
    let n = slike.length
    // console.log(slike[0])
    return <>
        <Swiper
            navigation={true}
            modules={[Navigation, Autoplay]}
            className="slikeSwiper"
            loop={true}
            effect='slide'
            speed={800}
            autoplay={{
                delay: 5000,
            }}>
            {slike && Array.isArray(slike) && slike.map((slika) => {
                //console.log(slika)
                let pozNaslova = typeof slika.pozNaslova === 'undefined' ? 0 : slika.pozNaslova
                let velNaslova = typeof slika.velNaslova === 'undefined' ? 0 : slika.velNaslova
                let pozSlika = typeof slika.pozSlika === 'undefined' ? 1 : slika.pozSlika
                let mobUrl = typeof slika.mobUrl === 'undefined' ? null : slika.mobUrl
                //console.log(velNaslova, pozNaslova, pozSlika)
                // console.log(slika)
                return <SwiperSlide className=''>
                    <div className='slide' id={slika.zatamljenje ? 'zatamljenje' : ''}>
                        <Link to={slika.link_strane}>
                            <img className={`glavna-slika komp ${(mobUrl != null ? 'ima' : '')}`} src={slika.url} id={pozSlikePoID[pozSlika]}></img>
                            {mobUrl && <img className='glavna-slika fon' src={slika.mobUrl} id={pozSlikePoID[pozSlika]}></img>}
                        </Link>
                        <div id={'naslov_' + pozNaslovaPoID[pozNaslova]}>
                            <Reveal>
                                <div className='naslov' id={velNaslovaPoID[velNaslova]}>
                                    {/* <h1 dangerouslySetInnerHTML={{__html: slika.naslov}}></h1> */}
                                    {slika.naslov !== undefined || slika.naslov != null ? slika.naslov.split('\r\n').map((x) => { return <h1>{x}</h1> }) : <></>}
                                </div>
                            </Reveal>
                        </div>
                    </div>
                </SwiperSlide>
            })}
        </Swiper>
        {n == 1 && <NavigacijaWidget naslov={slike[0].naslov}></NavigacijaWidget>}
    </>
}

export default Slike2