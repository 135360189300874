import { useEffect, useState } from "react";
import Obavestenje from "./Obavestenje";
import ReactPaginate from "react-paginate";
import apiEndpoint from "../../test/config";
import InfiniteScroll from "react-infinite-scroll-component";
import { Audio } from 'react-loader-spinner'

function VaznaObavestenja() {
    const amount = 3

    let query = `{
        allObavestenja(first: ${amount}, after: "<after>") {
          edges {
            node {
              vazno
              teloObavestenja
              naslov
              date
              fajl {
                title
                mediaItemUrl
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }`;

    const [obavestenja, setObavestenja] = useState([]);
    const [pageInfo, setPageInfo] = useState({
        cursor: '',
        hasMore: true,
    })

    const jsonToData = (jsonData) => {
        let pageData = jsonData['allObavestenja']['pageInfo']
        var edges = jsonData['allObavestenja']['edges']
        var obavestenjaArr = edges.map((edge) => {
            return {
                naslov: edge['node']['naslov'],
                vazno: edge['node']['vazno'],
                tekst: edge['node']['teloObavestenja'],
                datum: edge['node']['date'].match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join("."),
                fajlovi: edge['node']['fajl'] == null ? [] : edge['node']['fajl'].map((fajl) => {
                    var extensions = fajl['mediaItemUrl'].match(/\.([0-9a-z]+)(?:[\?#]|$)/i)
                    var fileExt = extensions.pop()
                    //console.log(`extenzija :    ${fileExt}`)
                    return {
                        ext: fileExt,
                        naslov: fajl['title'],
                        link: fajl['mediaItemUrl']
                    }
                })
            }
        })
        console.log(pageData)
        setPageInfo({
            ...pageInfo,
            cursor: pageData['endCursor'],
            hasMore: pageData['hasNextPage'],
        })
        setObavestenja(obavestenja.concat(obavestenjaArr))
    }

    useEffect(loadMore, []);

    function loadMore() {
        let queryStr = query.replace(/<after>/, pageInfo.cursor)
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query: queryStr
                })
            }).then((response) => response.json()).then((data) => jsonToData(data['data']));
    }

    return <>
        <div className="obavestenja-w65">
            <div className="obavestenja-wrapper">
                <h1>Огласна табла</h1>
                <div className="hl"></div>
                <div className="obavestenja">
                    <InfiniteScroll next={loadMore} hasMore={pageInfo.hasMore} dataLength={obavestenja.length} loader={
                        <div className="infinite-loading-indicator">
                        <Audio height="50" width="50" color="#07829D"/>
                        </div>
                    }> {obavestenja.map((obavestenje, index) => <Obavestenje key={index} data={obavestenje} />)}
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    </>
}

export default VaznaObavestenja
