import cvijicL from '../../res/images/cvijicL.svg';
import cvijicD from '../../res/images/cvijicD.svg';
import { useEffect, useState } from 'react';
import apiEndpoint from '../../test/config';
import { useMediaQuery } from 'react-responsive';

function Cvijic() {
  let query = `{
        pages (where: {name: "Pocetna"}){
          edges {
            node {
              pocetna {
                horizontalnakutija {
                  tekst
                }
              }
            }
          }
        }
      }`
  const [data, setData] = useState('');
  const dataToKutija = (jsonData) => {
    jsonData = jsonData['pages']['edges'][0]['node']['pocetna']['horizontalnakutija']
    let tekst = jsonData['tekst']
    setData(tekst)
  }
  let backup = {
    tekst: '<p>„Гимназија интелигенцију и карактер,<br>можда више, снажније, <br>и у неким правцима дубље него универзитет; <br>она је од великог утицаја на дух и моралну вредност <br>будућих интелектуалних нараштаја.<br>Поред универзитета, <br>од ње највише зависи <br>каква ће се морална и духовна атмосфера <br>развити у држави, <br>какав ће тим добити њена цивилизација, <br>и напослетку, <br>да ли ће се успоравати или ометати <br>развијање великих личности, <br>у којима се до највећег степена<br>изражавају особине једног народа.“</p><h1>- Јован Цвијић</h1>'
  }
  // const BackupText = () => {
  //   setData(backup);
  // }
  useEffect(() => {
    fetch(apiEndpoint,
      {
        method: 'POST',
        headers: {
          "Content-Type": 'application/json',
          "Accept": 'application/json'
        },
        body: JSON.stringify({
          query
        })
      }).then((response) => response.json()).then((data) => dataToKutija(data['data']))
  }, [])

  const isMobile = useMediaQuery({ query: '(max-width: 910px)' })
  
  return <>
    { }
    <div className={`cvijic-omotac ${isMobile ? '' : 'hidden zoom-out blur'}`}>
      <img className={`slika ${isMobile ? '' : 'hidden left blur delay05'}  `} id="s1" src={cvijicL} ></img>
      <div className={`tekst hidden zoom-out blur delay05`} dangerouslySetInnerHTML={{ __html: data }}>
      </div>
      <img className={`slika ${isMobile ? '' : 'hidden right blur delay05'}`} id="s2" src={cvijicD}></img>
    </div>
  </>
}

export default Cvijic