import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Generisana from "../../komponente/Generisane/Generisana"
import Greska from "../NotFound/404_stranica"
import apiEndpoint from "../../test/config"
import Loading from "../Loading stranica/Loading"
import PocetnaStranica from "../Pocetna/PocetnaStranica"

function GenerisanaStranica() {
    let ime = window.location.pathname.replace(/^\/+|\/+$/g, '')
    let query = `{
        stranice(first: 100000) {
          nodes {
            id
            infostranice {
              linkslug
            }
          }
        }
      }`

    const jsonToData = (data) => {
        let slugsData = data.map((str) => {
            return {
                id: str['id'],
                slug: str['infostranice']['linkslug'],
            }
        })
        for (let i = 0; i < slugsData.length; i++) {
            if (slugsData[i].slug == ime) {
                setId(slugsData[i].id)
                break;
            }
        }
        setUcitano(true)
    }
    const [id, setId] = useState('')
    const [ucitano, setUcitano] = useState(false)
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => jsonToData(data['data']['stranice']['nodes']));
    }, []);

    return <>
        {ucitano ? id != '' ? <Generisana id={id}></Generisana> : <PocetnaStranica></PocetnaStranica> : <Loading></Loading>}
    </>
}

export default GenerisanaStranica