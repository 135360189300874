import { useEffect, useState } from "react"
import Galerije from "../../komponente/Galerije/Galerije"
import Slike from "../../komponente/Pocetna/Slike"
import VestiWidget from "../../komponente/Widgets/VestiWidget"
import WrapperSidebar from "../../komponente/WrapperSidebar"
import apiEndpoint from "../../test/config"
import pozicijeSlika from "../../test/pozicije"
import Slike2 from "../../komponente/Pocetna/Slike2"

function GalerijeStranica() {
    let query = `{
        post(id: "cG9zdDoyNTM2") {
          glavneSlike {
            galerijastranica {
              deskripcija
              opcijenaslova
              naslov
              shadow
              drugaslika
              slikanamobilnom {
                sourceUrl
              }
              zoomdirection
              glavnaslika {
                sourceUrl
              }
            }
          }
        }
      }`
    const [izgled, setIzgled] = useState([]);
    const jsonUSlike = (jsonData) => {
        let opcijeNaslova = jsonData['post']['glavneSlike']['galerijastranica']['opcijenaslova'] == null ? ['levo'] : jsonData['post']['glavneSlike']['galerijastranica']['opcijenaslova']
        let velNaslova = opcijeNaslova.includes('velikaslova') ? 2 : opcijeNaslova.includes('srednjaslova') ? 1 : 0
        let pozNaslova = opcijeNaslova.includes('levo') ? 0 : opcijeNaslova.includes('desno') ? 2 : opcijeNaslova.includes('centar') ? 1 : 0

        let slika = [
            {
                zatamljenje: jsonData["post"]["glavneSlike"]["galerijastranica"]['shadow'] == null ? false : true,
                mobUrl: jsonData["post"]["glavneSlike"]["galerijastranica"]['drugaslika'] ? (jsonData["post"]["glavneSlike"]["galerijastranica"]['slikanamobilnom'] == null ? null : jsonData["post"]["glavneSlike"]["galerijastranica"]['slikanamobilnom']['sourceUrl']) : null,
                redniBroj: 0,
                naslov: jsonData["post"]["glavneSlike"]["galerijastranica"]["naslov"],
                pozSlika: pozicijeSlika[jsonData["post"]["glavneSlike"]["galerijastranica"]["zoomdirection"]],
                opis: jsonData["post"]["glavneSlike"]["galerijastranica"]["deskripcija"] === null ? ' ' : jsonData["post"]["glavneSlike"]["galerijastranica"]["deskripcija"],
                link_strane: '',
                pozNaslova: pozNaslova,
                velNaslova: velNaslova,
                url: jsonData["post"]["glavneSlike"]["galerijastranica"]["glavnaslika"]["sourceUrl"],
            }
        ]
        setIzgled(slika);
    }
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => jsonUSlike(data['data']))
    }, []);
    return <>
        <Slike2 slike={izgled}></Slike2>
        <div style={{ marginTop: '100px' }}>
            <Galerije></Galerije>
        </div>
    </>
}

export default GalerijeStranica