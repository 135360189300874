import { useEffect, useState } from "react"
import Slike from "../../komponente/Pocetna/Slike"
import VestiWidget from "../../komponente/Widgets/VestiWidget"
import WrapperSidebar from "../../komponente/WrapperSidebar"
import { Link } from "react-router-dom"
import apiEndpoint from "../../test/config"
import pozicijeSlika from "../../test/pozicije"
import Slike2 from "../../komponente/Pocetna/Slike2"

function PredmetiListaStranica() {
	let query = `{
    post(id: "cG9zdDoyNTM2") {
      glavneSlike {
        predmetistranica {
          naslov
          shadow
          drugaslika
          slikanamobilnom {
            sourceUrl
          }
          deskripcija
          opcijenaslova
          zoomdirection
          glavnaslika {
            sourceUrl
          }
        }
      }
    }
    predmeti(first: 1000) {
      edges {
        node {
          id
          predmet {
            icon {
              sourceUrl
            }
            imepredmeta
            bojapredmeta
            strucnovece {
              name
			  slug
            }
          }
        }
      }
    }
  }`
	const [izgled, setIzgled] = useState([]);
	const dataToVesti = (data) => {
		let opcijeNaslova = data['post']['glavneSlike']['predmetistranica']['opcijenaslova'] == null ? ['levo'] : data['post']['glavneSlike']['predmetistranica']['opcijenaslova']
		let velNaslova = opcijeNaslova.includes('velikaslova') ? 2 : opcijeNaslova.includes('srednjaslova') ? 1 : 0
		let pozNaslova = opcijeNaslova.includes('levo') ? 0 : opcijeNaslova.includes('desno') ? 2 : opcijeNaslova.includes('centar') ? 1 : 0

		let slika = [
			{
				zatamljenje: data["post"]["glavneSlike"]["predmetistranica"]['shadow'] == null ? false : true,
				mobUrl: data["post"]["glavneSlike"]["predmetistranica"]['drugaslika'] ? (data["post"]["glavneSlike"]["predmetistranica"]['slikanamobilnom'] == null ? null : data["post"]["glavneSlike"]["predmetistranica"]['slikanamobilnom']['sourceUrl']) : null,
				redniBroj: 0,
				naslov: data["post"]["glavneSlike"]["predmetistranica"]["naslov"],
				pozSlika: pozicijeSlika[data["post"]["glavneSlike"]["predmetistranica"]["zoomdirection"]],
				opis: data["post"]["glavneSlike"]["predmetistranica"]["deskripcija"],
				link_strane: '',
				pozNaslova: pozNaslova,
				velNaslova: velNaslova,
				url: data["post"]["glavneSlike"]["predmetistranica"]["glavnaslika"]["sourceUrl"],
			}
		]
		setIzgled(slika);

		function isNumeric(str) {
			return !isNaN(str) && !isNaN(parseFloat(str))
		}
		let predmetiData = data['predmeti']['edges'].map((sekcija) => {
			return {
				id: sekcija['node']['id'],
				ime: sekcija['node']['predmet']['imepredmeta'],
				slika: sekcija['node']['predmet']['icon']['sourceUrl'],
				boja: sekcija['node']['predmet']['bojapredmeta'],
				strucnoVece: sekcija['node']['predmet']['strucnovece']['name'],
				slug: isNumeric(sekcija['node']['predmet']['strucnovece']['slug']) ? parseInt(sekcija['node']['predmet']['strucnovece']['slug']) : 1000,
			}
		})
		// console.log(predmetiData)
		predmetiData.sort((a,b) => {return a.slug - b.slug})
		let strucnaVeca = new Set()
		let predmetiDict = {}
		predmetiData.forEach(element => {
			strucnaVeca.add(element.strucnoVece)
		});
		Array.from(strucnaVeca).forEach((el) => {
			predmetiDict[`${el}`] = []
		})
		predmetiData.forEach((predmet) => {
			predmetiDict[predmet.strucnoVece].push(predmet)
		})
		// console.log(predmetiDict)
		setPredmeti(predmetiDict)
		setUcitano(true)
	}
	const [predmeti, setPredmeti] = useState({})
	const [ucitano, setUcitano] = useState(false)
	useEffect(() => {
		fetch(apiEndpoint,
			{
				method: 'POST',
				headers: {
					"Content-Type": 'application/json',
					"Accept": 'application/json'
				},
				body: JSON.stringify({
					query
				})
			}).then((response) => response.json()).then((data) => {
				dataToVesti(data['data'])
			});
	}, []);

	const [widgets, setWidgets] = useState([])
	useEffect(() => {
		fetch(apiEndpoint, {
			method: 'POST', headers: { "Content-Type": 'application/json', "Accept": 'application/json' },
			body: JSON.stringify({
				query: `{pages(where: {name: "SideBar"}) {nodes {sidebar {
                predmeti
              }}}}` })
		}).then((response) => response.json()).then((data) => {
			setWidgets(data['data']['pages']['nodes'][0]['sidebar']['predmeti'])
		});
	}, []);

	return <>
		<Slike2 slike={izgled}></Slike2>
		<div className="predmeti-margin">
			<WrapperSidebar element={<>
				{ucitano && Object.keys(predmeti).map(key => {
					return <div className="predmeti-kategorija">
						<h1 className="kategorija">{key}</h1>
						<div className="predmeti">
							{ucitano && predmeti[key].map((predmet) => {
								return <div className="predmet">
									<div className="slika">
										<img src={predmet['slika']}></img>
										<Link to={`/predmet/${predmet.id}`}></Link>
									</div>
									<Link to={`/predmet/${predmet.id}`} style={{ background: predmet.boja }}>{predmet['ime']}</Link>
								</div>
							})}
						</div>
					</div>
				})}
			</>} vl_slide={true} widget_list={null}></WrapperSidebar>
		</div>
	</>
}

export default PredmetiListaStranica