import { useLayoutEffect, useState } from "react"
import close from '../../res/images/close.png';
import cioda from '../../res/images/cioda.png'
import obavestenje_dsk from '../../res/images/obavestenje.png'
import obavestenje_mob from '../../res/images/obavestenje-mob.png'
import { FileIcon, defaultStyles } from "react-file-icon"
import observer from "../../animacije/Observer";

function Oglas({ obavestenje, index }) {
    const [popup, setPopup] = useState(false)

    const togglePopup = () => setPopup(!popup)

    if (popup) {
        document.body.classList.add('active-modal')
    }
    else {
        document.body.classList.remove('active-modal')
    }

    let hue_rotacija = [135, 200, 0, 260]

    useLayoutEffect(() => {
        setTimeout(() => { document.querySelectorAll('.hidden').forEach((el) => { observer.observe(el) }) }, 500)
    }, [])

    return <>
        <div className='hidden down blur down' id={obavestenje.vazno ? "vazno" : "nevazno"} onClick={togglePopup}>
            <p id='text' className="hidden">{obavestenje.naslov}</p>
            <p id='datum'>{obavestenje.datum}</p>
            <img id='cioda' className="hidden zoom15 blur delay1 duration05" src={cioda} ></img>
            {/* style={{ filter: 'hue-rotate(' + hue_rotacija[index] + 'deg)' }} */}
        </div>
        {popup &&
            <>
                <section className="modal">
                    <section className="overlay" onClick={togglePopup}>
                    </section>
                    <section className="content">
                        <section className="close" onClick={togglePopup}>
                            <img src={close}></img>
                        </section>
                        <h1>{obavestenje['naslov']}</h1>
                        <section className="hl"></section>
                        <section className='tekst' dangerouslySetInnerHTML={{ __html: obavestenje['tekst'] }}></section>
                        {obavestenje['fajlovi'].length > 0 &&
                            <>
                                <h1 className="fajlovi-tekst">Приложена документа : </h1>
                                <ul className="fajlovi">
                                    {obavestenje['fajlovi'].map((fajl) => {
                                        return <>
                                            <li className="fajl">
                                                <section className="slikaFajla">
                                                    <FileIcon extension={fajl['ext']} {...defaultStyles[`${fajl['ext']}`]} />
                                                </section>
                                                <a href={fajl['link']}>{fajl['naslov']}</a>
                                            </li>
                                        </>
                                    })}
                                </ul>
                            </>}

                    </section>
                </section>
            </>
        }
    </>
}

export default Oglas