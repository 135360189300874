import { Link } from "react-router-dom"
import slika_url from '../../res/images/Default.png'

function NeNastavnici({zaposleni, tip}){
    return <>
        <div className="nenastavnici-wrapper">
            <div className="layout">
                {zaposleni.map((zaposljen) => {
                    return <>
                        <div className="zaposljen">
                            <div className="leva-strana">
                                <div className="slika">    
                                    <img src={zaposljen['slika'] ? zaposljen['slika'] : slika_url}></img>
                                    <Link to={`/kolektiv/${zaposljen['id']}`}></Link>
                                </div>
                                <Link to={`/kolektiv/${zaposljen['id']}`}>{zaposljen.ime}</Link>
                            </div>
                            <div className="desna-strana">
                                {/* <Link to={`/kolektiv/${zaposljen['id']}`}>{tip.toUpperCase()}: {zaposljen.ime}</Link> */}
                                <div className="opis" dangerouslySetInnerHTML={{__html: zaposljen.opis}}></div>
                            </div>
                        </div>
                    </>
                })}
            </div>
        </div>
    </>
}

export default NeNastavnici