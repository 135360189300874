import { useEffect, useState } from "react"
import Slike from "../../komponente/Pocetna/Slike"
import VestiWidget from "../../komponente/Widgets/VestiWidget"
import WrapperSidebar from "../../komponente/WrapperSidebar"
import { Link } from "react-router-dom"
import OstaleSekcije from "../../komponente/Sekcije/OstaleSekcije"
import apiEndpoint from "../../test/config"
import pozicijeSlika from "../../test/pozicije"
import Slike2 from "../../komponente/Pocetna/Slike2"

function SekcijeStranica() {
    let query = `{
        post(id: "cG9zdDoyNTM2") {
          glavneSlike {
            sekcijestranica {
              naslov
              shadow
              drugaslika
              slikanamobilnom {
                sourceUrl
              }
              zoomdirection
              deskripcija
              opcijenaslova
              glavnaslika {
                sourceUrl
              }
            }
          }
        }
        sekcije(first: 100000) {
          nodes {
            id
            sekcija {
              malaslika {
                sourceUrl
              }
              ime
            }
          }
        }
      }`
    const [izgled, setIzgled] = useState([]);
    const dataToVesti = (data) => {
        let opcijeNaslova = data['post']['glavneSlike']['sekcijestranica']['opcijenaslova'] == null ? ['levo'] : data['post']['glavneSlike']['sekcijestranica']['opcijenaslova']


        let velNaslova = opcijeNaslova.includes('velikaslova') ? 2 : opcijeNaslova.includes('srednjaslova') ? 1 : 0
        let pozNaslova = opcijeNaslova.includes('levo') ? 0 : opcijeNaslova.includes('desno') ? 2 : opcijeNaslova.includes('centar') ? 1 : 0
        // console.log(opcijeNaslova, velNaslova, pozNaslova)


        let slika = [
            {
                zatamljenje: data["post"]["glavneSlike"]["sekcijestranica"]['shadow'] == null ? false : true,
                mobUrl: data["post"]["glavneSlike"]["sekcijestranica"]['drugaslika'] ? (data["post"]["glavneSlike"]["sekcijestranica"]['slikanamobilnom'] == null ? null : data["post"]["glavneSlike"]["sekcijestranica"]['slikanamobilnom']['sourceUrl']) : null,
                redniBroj: 0,
                naslov: data["post"]["glavneSlike"]["sekcijestranica"]["naslov"],
                pozSlika: pozicijeSlika[data["post"]["glavneSlike"]["sekcijestranica"]["zoomdirection"]],
                opis: data["post"]["glavneSlike"]["sekcijestranica"]["deskripcija"],
                link_strane: '',
                pozNaslova: pozNaslova,
                velNaslova: velNaslova,
                url: data["post"]["glavneSlike"]["sekcijestranica"]["glavnaslika"]["sourceUrl"],
            }
        ]
        setIzgled(slika);
        let sekcijaData = data['sekcije']['nodes'].map((sekcija) => {
            return {
                id: sekcija['id'],
                ime: sekcija['sekcija']['ime'],
                slika: sekcija['sekcija']['malaslika']['sourceUrl']
            }
        })
        setSekcije(sekcijaData)
        setUcitano(true)
    }
    const [sekcije, setSekcije] = useState([])
    const [ucitano, setUcitano] = useState(false)
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => {
                dataToVesti(data['data'])
            });
    }, []);

    const [widgets, setWidgets] = useState([])
    useEffect(() => {
        fetch(apiEndpoint, {
            method: 'POST', headers: { "Content-Type": 'application/json', "Accept": 'application/json' },
            body: JSON.stringify({
                query: `{pages(where: {name: "SideBar"}) {nodes {sidebar {
                    sekcije
                }}}}` })
        }).then((response) => response.json()).then((data) => {
            setWidgets(data['data']['pages']['nodes'][0]['sidebar']['sekcije'])
        });
    }, []);

    return <>
        <Slike2 slike={izgled}></Slike2>
        <div className="sekcije-margin">
            <WrapperSidebar element={<>
                <div className="sekcije">
                    {ucitano && sekcije.map((sekcija) => {
                        return <div className="sekcija">
                            <div className="slika">
                                <img src={sekcija['slika']}></img>
                                <Link to={`/sekcija/${sekcija.id}`}></Link>
                            </div>
                            <Link to={`/sekcija/${sekcija.id}`}>{sekcija['ime']}</Link>
                        </div>
                    })}
                </div>
            </>} vl_slide={true} widget_list={widgets}></WrapperSidebar>
        </div>
    </>
}

export default SekcijeStranica