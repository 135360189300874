import { motion, useScroll, useSpring, useTransform } from "framer-motion"
import { useRef } from "react"
import { Link } from "react-router-dom"
const slika = "https://picsum.photos/1920/1080"
function Galerija2Slika({data, index}){
    const ref = useRef(null)
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ['1 0.9', '1 1']
    })

    const scaleX = useSpring(scrollYProgress, {
        damping: 30,
        stiffness: 120,
        restDelta: 0.001
    })

    const scale = useTransform(scaleX, [1, 0], [0.8, 1])
    const opacity = useTransform(scaleX, [1, 0], [0.2, 1])

//     return <motion.div 
//             className={`slika s${index+1}`}
//             ref={ref}
//             style={{
//                 scale: scale,
//                 opacity: opacity
//             }}>
//         {data.id != null && <Link to={`/galerije/${data.id}`}></Link>}
//         <img src={data.slika == null ? slika : data.slika}></img>
//   </motion.div>
    return <div
        className={`slika s${index+1} hidden zoom15 delay05`}
        ref={ref}
        >
        {data.id != null && <Link to={`/galerije/${data.id}`}></Link>}
        <img src={data.slika == null ? slika : data.slika}></img>
    </div>
}

export default Galerija2Slika