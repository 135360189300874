import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import WrapperSidebar from "../../komponente/WrapperSidebar"
import ObavestenjaWidgets from "../../komponente/Widgets/ObavestenjaWidgets"
import Slike from "../../komponente/Pocetna/Slike"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { icon } from "@fortawesome/fontawesome-svg-core"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faGraduationCap, faLink, faPaperclip } from "@fortawesome/free-solid-svg-icons"
import ZaposljenInfo from "../../komponente/Kolektiv/ZaposljenInfo"
import CircleSwiper from "../../komponente/CircleSwiper"
import OstaloOsoblje from "../../komponente/Kolektiv/OstaloOsoblje"
import { Autoplay } from "swiper"
import apiEndpoint from "../../test/config"
import slika_url from '../../res/images/Default.png'
import { resourceUsage } from "process"
import Slike2 from "../../komponente/Pocetna/Slike2"

function ZaposljenStranica() {
    let { id } = useParams()
    let query = `{
        kolektivBy(id: "${id}") {
          nastavnik {
            email
            imeprezime
            link {
              url
              title
            }
            nastavnoiskustvo
            obrazovanje
            ostalo
            otvorenavrata
            predmeti {
              ... on Predmet {
                predmet {
                  imepredmeta
                }
                id
              }
            }
            profesionalnoiskustvo
            slika {
              sourceUrl
            }
            tipzaposlenog {
                name
            }
          }
        }
      }`


    const dataToVesti = (data) => {
        let nastavnik = data['nastavnik']
        let zaposljenData = {
            ime: nastavnik['imeprezime'],
            email: nastavnik['email'] == null ? null : nastavnik['email'],
            linkStrane: nastavnik['link'] == null ? null : {
                url: nastavnik['link']['url'],
                naslov: nastavnik['link']['title'] == null ? null : nastavnik['link']['title']
            },
            predmeti: nastavnik['predmeti'] == null ? null : nastavnik['predmeti'].map((predmet) => {
                if (predmet == null) return null
                return {
                    id: predmet['id'],
                    naziv: predmet['predmet']['imepredmeta']
                }
            }),
            nastavnoiskustvo: nastavnik['nastavnoiskustvo'] == null ? null : nastavnik['nastavnoiskustvo'],
            obrazovanje: nastavnik['obrazovanje'] == null ? null : nastavnik['obrazovanje'],
            ostalo: nastavnik['ostalo'] == null ? null : nastavnik['ostalo'],
            otvorenavrata: nastavnik['otvorenavrata'] == null ? null : nastavnik['otvorenavrata'],
            profesionalnoiskustvo: nastavnik['profesionalnoiskustvo'] == null ? null : nastavnik['profesionalnoiskustvo'],
            slika: nastavnik['slika'] == null ? null : nastavnik['slika']['sourceUrl'],
            tipovi: nastavnik['tipzaposlenog'].map((tip) => { return tip['name'] }),
        }
        setZaposljen(zaposljenData)
        setUcitano(true)
    }
    const [zaposljen, setZaposljen] = useState({})
    const [ucitano, setUcitano] = useState(false)
    useEffect(() => {
        fetch(apiEndpoint,
            {
                method: 'POST',
                headers: {
                    "Content-Type": 'application/json',
                    "Accept": 'application/json'
                },
                body: JSON.stringify({
                    query
                })
            }).then((response) => response.json()).then((data) => dataToVesti(data['data']['kolektivBy']));
    }, []);

    let slika = [
        {
            zatamljenje: true,
            redniBroj: 0,
            naslov: "Наставник",
            link_strane: '',
            url: 'https://wp.gimnazijasvetisava.edu.rs/wp-content/uploads/2023/05/20191002_133556a.jpg',
        }
    ]

    return <>
        <div>
            <Slike2 slike={slika}></Slike2>
            <div className="zaposljen-margin">
                {ucitano &&
                    <WrapperSidebar vl_slide={true} element={
                        <div className="zaposljen">
                            <div className="glavni-deo">
                                <div className="slika">
                                    <img src={zaposljen['slika'] == null ? slika_url : zaposljen['slika']}></img>
                                </div>
                                <div className="ostatak">
                                    <h1 className="ime">{zaposljen['ime']}</h1>
                                    {zaposljen['tipovi'].map(tip => {
                                        return <Link to={`/kolektiv?kategorija=${tip}`} className="tip">{tip}</Link>
                                    })}
                                    <div className="email">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                        <a href={`mailto:${zaposljen['email']}`}>{zaposljen['email']}</a>
                                    </div>
                                    {zaposljen['linkStrane'] &&
                                        <div className="link">
                                            <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>
                                            <a href={zaposljen['linkStrane']['url']}>{zaposljen['linkStrane']['naslov'] == '' ? zaposljen['linkStrane']['url'] : zaposljen['linkStrane']['naslov']}</a>
                                        </div>
                                    }
                                    {zaposljen['predmeti'] &&
                                        <div className="predmeti">
                                            <FontAwesomeIcon icon={faGraduationCap} />
                                            <div className="predmeti-list">
                                                {zaposljen['predmeti'].map((predmet) => {
                                                    if (predmet == null) return <></>
                                                    return <Link to={`/predmet/${predmet['id']}`}>{predmet['naziv']}</Link>
                                                })}
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="ostatak telefon">
                                <h1 className="ime">{zaposljen['ime']}</h1>
                                {zaposljen['tipovi'].map(tip => {
                                    return <Link to={`/kolektiv?kategorija=${tip}`} className="tip">{tip}</Link>
                                })}
                                <div className="email">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                    <a href={`mailto:${zaposljen['email']}`}>{zaposljen['email']}</a>
                                </div>
                                {zaposljen['linkStrane'] &&
                                    <div className="link">
                                        <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>
                                        <a href={zaposljen['linkStrane']['url']}>{zaposljen['linkStrane']['naslov'] == '' ? zaposljen['linkStrane']['url'] : zaposljen['linkStrane']['naslov']}</a>
                                    </div>
                                }
                                {zaposljen['predmeti'] &&
                                    <div className="predmeti">
                                        <FontAwesomeIcon icon={faGraduationCap} />
                                        <div className="predmeti-list">
                                            {zaposljen['predmeti'].map((predmet) => {
                                                if (predmet == null) return <></>
                                                return <Link to={`/predmet/${predmet['id']}`}>{predmet['naziv']}</Link>
                                            })}
                                        </div>
                                    </div>
                                }
                            </div>
                            {zaposljen['otvorenavrata'] && <ZaposljenInfo naslov='Отворена врата' tekst={zaposljen['otvorenavrata']}></ZaposljenInfo>}
                            {zaposljen['obrazovanje'] && <ZaposljenInfo naslov='Образовање' tekst={zaposljen['obrazovanje']}></ZaposljenInfo>}
                            {zaposljen['profesionalnoiskustvo'] && <ZaposljenInfo naslov='Професионално искуство' tekst={zaposljen['profesionalnoiskustvo']}></ZaposljenInfo>}
                            {zaposljen['nastavnoiskustvo'] && <ZaposljenInfo naslov='Наставно искуство' tekst={zaposljen['nastavnoiskustvo']}></ZaposljenInfo>}
                            {zaposljen['ostalo'] && <ZaposljenInfo naslov='Сопствени текст' tekst={zaposljen['ostalo']} hl={false} wysiwyg={true}></ZaposljenInfo>}
                            <OstaloOsoblje></OstaloOsoblje>
                        </div>
                    }
                        widgets={[<ObavestenjaWidgets broj_obavestenja={2}></ObavestenjaWidgets>]}></WrapperSidebar>
                }
            </div>
        </div >
    </>
}

export default ZaposljenStranica