import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import WrapperSidebar from "../WrapperSidebar"
import VestiWidget from "../Widgets/VestiWidget"
import ObavestenjaWidgets from "../Widgets/ObavestenjaWidgets"
import LinkoviWidget from "../Widgets/LinkoviWidget"
import close from '../../res/images/close.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Audio } from 'react-loader-spinner'
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import apiEndpoint from "../../test/config"
import InfiniteScroll from "react-infinite-scroll-component"
import { debug, log } from "console"


function Vesti({ orderedIDs }) {

    const params = new URLSearchParams(window.location.search);
    const amount = 2

    const query = `{
        vest(id: "<id>") {
            id
            acf {
              podnaslov
              naslov
              datumobjave
              telovesti
              thumbnail {
                sourceUrl
              }
            }
            date
          }
      }`

    const dataToVesti = (vest) => {
        let acf = vest['acf']
        let vestData = {
            slug: vest['id'],
            naslov: acf['naslov'],
            podnaslov: acf['podnaslov'],
            tekst: acf['telovesti'],
            slika: acf['thumbnail']['sourceUrl'],
            izabranidatum: acf['datumobjave'] == null ? vest["date"].match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join(".") : acf['datumobjave'].split("/").join("."),
            datum: vest["date"].match(/\d{4}-\d{2}-\d{2}/)[0].split("-").reverse().join(".")
        }
        return vestData
    }
    const [vesti, setVesti] = useState([])
    const [nextID, setNextID] = useState(0)
    const [vestiB, setVestiB] = useState(false)

    function fetchData() {
        // console.log(`current id: ${nextID}`);
        // console.log('loading');
        const left = orderedIDs.length - nextID;
        const toAdd = Math.min(amount, left);

        if (toAdd <= 0) {
            //console.log('no more items to load');
            return;
        }

        const queries = [];
        for (let i = 0; i < toAdd; i++) {
            const queryStr = query.replace(/<id>/, orderedIDs[nextID + i].id);
            queries.push(queryStr);
        }

        const newData = [];

        Promise.all(
            queries.map(queryStr =>
                fetch(apiEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    },
                    body: JSON.stringify({ query: queryStr })
                }).then(response => response.json())
            )
        ).then(dataArray => {
            dataArray.forEach(data => {
                newData.push(data.data.vest);
            });

            const updatedVesti = [...vesti];
            newData.forEach(vest => {
                const vestData = dataToVesti(vest);
                updatedVesti.push(vestData);
            });
            setVesti(updatedVesti);

            const next = nextID + toAdd >= orderedIDs.length ? -1 : nextID + toAdd;
            // console.log(`next id: ${next}`);
            setNextID(next);
            setVestiB(true)
        });
    }

    useEffect(fetchData, []);

    const mesec = (datum) => {
        switch (datum) {
            case '01':
                return 'Јануар'
            case '02':
                return 'Фебруар'
            case '03':
                return 'Март'
            case '04':
                return 'Април'
            case '05':
                return 'Мај'
            case '06':
                return 'Јун'
            case '07':
                return 'Јул'
            case '08':
                return 'Август'
            case '09':
                return 'Септембар'
            case '10':
                return 'Октобар'
            case '11':
                return 'Новембар'
            default:
                return 'Децембар'
        }
    }

    const [widgets, setWidgets] = useState([])
    useEffect(() => {
        fetch(apiEndpoint, {
            method: 'POST', headers: { "Content-Type": 'application/json', "Accept": 'application/json' },
            body: JSON.stringify({
                query: `{pages(where: {name: "SideBar"}) {nodes {sidebar {
                    vesti
                }}}}` })
        }).then((response) => response.json()).then((data) => {
            setWidgets(data['data']['pages']['nodes'][0]['sidebar']['vesti'])
        });
    }, []);

    return <div className="vesti-wrapper1">
        <WrapperSidebar element={
            <div className="vesti-list">
                <InfiniteScroll next={fetchData} hasMore={nextID != -1} dataLength={vesti.length} loader={
                    <div className="infinite-loading-indicator">
                        <Audio height="50" width="50" color="#07829D" />
                    </div>
                }>
                    {vestiB && vesti.map((vest) => {
                        return <>
                            <div className="vest">
                                <img src={vest['slika']}></img>
                                <Link className="cover" to={`/vesti/${vest.slug}`}></Link>
                                <div className="naslov-datum">
                                    <div className="datum">
                                        <h1>{vest['izabranidatum'].substring(0, 2) + '.'}</h1>
                                        <h2>{mesec(vest['izabranidatum'].substring(3, 5))}</h2>
                                    </div>
                                    <div className="hl"></div>
                                    <div className="naslov">
                                        <Link to={`/vesti/${vest.slug}`}>{vest['naslov']}</Link>
                                    </div>
                                </div>
                                <div className="vest-preview">{vest['podnaslov']}</div>
                                <Link to={`/vesti/${vest.slug}`}>Сазнај више</Link>
                            </div>
                        </>
                    })}
                    {/* {vesti} */}
                </InfiniteScroll>
            </div>}

            widget_list={widgets}
            vl_slide={true}></WrapperSidebar>
    </div>
}

export default Vesti    