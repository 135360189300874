import KolektivStranica from "../stranice/Kolektiv/KolektivStranica";
import TileFlip from "./EksterneKomponente/TileFlip/TileFlip";
import './EksterneKomponente/TileFlip/TileFlip.css'

function KompoenenteTest(){
    // return <TileFlip 
    // naslov='Nastavnici' 
    // poruka='Упис у средњу школу је једна од најважнијих прекретница у животу.' 
    // ikonica='https://gimnazijasvetisava.edu.rs/wp-content/uploads/2023/06/ucenici.svg'
    // boja='red'
    // width="200px"
    // height="100px"></TileFlip>
    return <><KolektivStranica></KolektivStranica></>
}

export default KompoenenteTest