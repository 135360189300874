import LinkoviWidget from "./Widgets/LinkoviWidget"
import ObavestenjaWidgets from "./Widgets/ObavestenjaWidgets"
import VestiWidget from "./Widgets/VestiWidget"

function WrapperSidebar({ element, widgets = [], vl_slide = true, widget_list = null}) {
    const string_to_widget = {}
    string_to_widget['Обавештења'] = <ObavestenjaWidgets broj_obavestenja={3}></ObavestenjaWidgets>
    string_to_widget['Линкови'] = <LinkoviWidget></LinkoviWidget>;
    string_to_widget['Новости'] = <VestiWidget broj_vesti={3}></VestiWidget>;
    string_to_widget['Мото или Текст'] = <VestiWidget broj_vesti={3}></VestiWidget>;

    if (widget_list != null)
    {
        widgets = []
        for (let i = 0; i < widget_list.length; i++){
            widgets.push(string_to_widget[widget_list[i]])
        }
    }

    return <div className="width-wrapper" id={widgets == null || widgets.length == 0 ? 'prazno' : ''}>
        <div className="width">
            <div className={vl_slide ? "levo" : "levo center"} id={widgets == null || widgets.length == 0 ? 'nista' : ''}>
                {element}
            </div>
            { widgets && <>
                {vl_slide && <div className="vl" style={vl_slide ? { position: 'sticky', top: '20px' } : {}}></div>}
                <div className="desno">
                    {widgets.map((x) => { return <>{x}</> })}
                </div>
            </>}
        </div>
    </div>
}

export default WrapperSidebar